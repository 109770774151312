import Layout from "../../../layout/Layout";
import Map from "../../../components/map/Map";
import vehicleMap from "../../../assets/images/allVehicleMap.png";
import LocationImg from "../../../assets/icons/location-icon.svg";

const LiveLocation = () => {
  return (
    <Layout>
      <div className={`flex flex-col`}>
        <div className="bg-green justify-end w-fit shadow-all-rounded flex flex-row gap-2 text-white px-4 py-2 rounded-2xl cursor-pointer mb-3">
          <img src={LocationImg} alt="icon" />
          <h1 className="text-lg">List of Vehicles</h1>
        </div>
        <div>
          <img src={vehicleMap} />
        </div>
      </div>
      {/* <Map /> */}
    </Layout>
  );
};

export default LiveLocation;
