import { useState, useEffect } from "react";
import Table from "../tables/Table";
import VehicleModal from "./VehicleModal";
import vehicle from "../../services/vehicles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VehicleTab = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const columnNames = ["Title", "Type", "Approval Date"];
  const [Id, setId] = useState('');
  const [edit, setEdit] = useState(false);
  const [TableContent, setData] = useState([{}]);
  const tableColumn = ["number", "vehicle_name", "created_at"];

  useEffect(() => {
    async function fetchAllVehicle() {
      const response = await vehicle.fetchVehicles();
      if (!response.exception) {
        setData(response.data);
      } else {
        toast.error(response.errors, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
    fetchAllVehicle();
  }, []);

  return (
    <div className="h-full overflow-y-auto">
      <Table
        TableContent={TableContent}
        setIsOpen={setIsOpen}
        columnNames={columnNames}
        tableColumn={tableColumn}
        setId={setId}
        setEdit={setEdit}
      />
      <VehicleModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        edit={edit}
        Id={Id}
      />
    </div>
  );
};

export default VehicleTab;
