import EditImg from "../../../assets/images/svgs/edit.svg";
const ActiveVehicleTabelItem = ({ tab, id, setShowVehicleLocation }) => {
  return (
    <div
      className={`flex ${"grid-cols-5"} hover:bg-light-yellow justify-between px-10 md:px-16 lg:px-28 py-3`}
    >
      <p className="font-semibold text-lg">{tab.vehicle}</p>
      <img
        src={EditImg}
        className="cursor-pointer pr-10"
        onClick={() => setShowVehicleLocation(true)}
        alt="edit-icon"
      />
    </div>
  );
};

export default ActiveVehicleTabelItem;
