import React from "react";
import RoutesDirections from "./types";
import { Navigate, Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";

const ProtectedRoutes = ({ children }) => {
  let token = localStorage.getItem("token");
  if (token) {
    const decodedToken = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    if (decodedToken.exp < currentTime) {
      return <Navigate to={RoutesDirections.Login} />;
    } else {
      return children ? children : <Outlet />;
    }
  } else {
    return <Navigate to={RoutesDirections.Login} />;
  }
};

export default ProtectedRoutes;
