import { IoClose } from "react-icons/io5";
import Modal from "react-modal";
import useWindowSize from "../../../utils/useWindowSize";
import CameraBtn from "../../../assets/images/svgs/camera.svg";
import UniversityForm from "./UniversityForm";

export default function StudentUniversityForm({ modalOpen, setModalOpen }) {
  const { width } = useWindowSize();

  const customStyles = {
    content: {
      top: "95px",
      left: "auto",
      right: "10px",
      bottom: "auto",
      maxHeight: "80vh",
      height: "100%",
      width:
        width < 768
          ? "23rem"
          : width > 768 && width < 1024
          ? "35rem"
          : width >= 1024 && "45rem",
      boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.25)",
      borderRadius: "20px",
      padding: "0px",
      overflowY: "none",
    },
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Modal isOpen={modalOpen} onRequestClose={closeModal} style={customStyles}>
      <div className="h-full flex flex-col justify-between relative pt-4">
        <div className="flex flex-row justify-between items-center sticky w-full">
          <div onClick={closeModal} className="cursor-pointer absolute left-3">
            <IoClose className="font-bold text-3xl" />
          </div>
          <div className="font-bold text-xl md:text-3xl mx-auto">
            Student-University
          </div>
        </div>
        <div className="h-full my-12 py-4">
          <div className="flex flex-row items-center justify-between px-6 max-[768px]:flex-col max-[768px]:gap-2">
            <h1 className="font-bold text-lg text-slate-600">
              Student Information
            </h1>
            <div className="p-3 bg-white shadow-all-rounded h-20 w-20 rounded-full cursor-pointer flex items-center justify-center">
              <img src={CameraBtn} alt="camera" className="h-4" />
              <input type="file" className="hidden" />
            </div>
          </div>
          <UniversityForm />
          <div className="bg-placeholder/50 p-5 rounded-b-xl flex flex-col sm:flex-row space-y-3 md:space-y-0 justify-end mt-4">
            <div className={`flex flex-col sm:flex-row items-center gap-6`}>
              <div className="bg-light-orange text-white w-36 text-center py-1 px-4 rounded-3xl shadow-all-rounded flex items-center space-x-1 justify-center cursor-pointer">
                <p>Meet</p>
              </div>

              <div className="bg-red text-white min-w-36 text-center py-1 px-4 rounded-3xl shadow-all-rounded cursor-pointer">
                Disapprove
              </div>
              <div className="bg-green text-white min-w-36 text-center py-1 px-4 rounded-3xl shadow-all-rounded cursor-pointer">
                Aprrove
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
