import Request from "./request";

const register = async (data) => {
  try {
    const response = await Request.post("manager/register", data);
    return { exception: false, data: response.data };
  } catch (error) {
    return { exception: true, errors: error.response.data.message };
  }
};

const login = async (data) => {
  try {
    const response = await Request.post("manager/login/web", data);
    localStorage.setItem("token", response.data.data.token);
    return { exception: false, data: response.data };
  } catch (error) {
    return { exception: true, errors: error.response.data.message };
  }
};

const logout = async (data) => {
  try {
    const response = await Request.post("manager/logout", data);
    localStorage.removeItem("token");
    return { exception: false, data: response.data };
  } catch (error) {
    return { exception: true, errors: error.response.data.message };
  }
};

const forgetPassword = async (data) => {
  try {
    const response = await Request.post("manager/forget-password", data);
    return { exception: false, data: response.data };
  } catch (error) {
    return { exception: true, errors: error.response.data.message };
  }
};

const getProfilePicture = async (data) => {
  try {
    const response = await Request.get("manager/profile", data);
    return { exception: false, data: response.data.data };
  } catch (error) {
    return { exception: true, errors: error.response.data.message };
  }
};

const setProfilePicture = async (data) => {
  try {
    const formData = new FormData();
    formData.append("profile_picture", data);
    const response = await Request.post("manager/profile/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return { exception: false, data: response.data.data };
  } catch (error) {
    return { exception: true, errors: error.response.data.message };
  }
};

export default {
  register,
  login,
  logout,
  forgetPassword,
  getProfilePicture,
  setProfilePicture,
};
