export default function AccountDeactivateModal({
  modalIsOpen,
  handleModal,
  handleCodeModal,
}) {
  return (
    <div
      className={`${
        modalIsOpen === true ? "block" : "hidden"
      } fixed top-80 left-80 z-50  w-96 p-4 overflow-x-hidden overflow-y-auto backdrop-blur-sm`}
    >
      <div className="relative w-full h-full max-w-2xl md:h-auto">
        <div className="relative bg-[#00000040] rounded-lg shadow pb-1 justify-between">
          <div className="flex items-start justify-between p-4">
            <h3 className="text-xl font-semibold text-black text-center">
              The six-digit code will be sent to your organization's head.
              Please get the code from him/her if you want to proceed with
              account deactivation.
            </h3>
          </div>
          <div className="flex justify-between mx-10 mb-1">
            <button
              className="bg-green text-white text-xl font-bold rounded-3xl px-7 py-2.5"
              type="button"
              onClick={() => handleModal(false)}
            >
              Cancel
            </button>
            <button
              className="bg-red text-white text-xl font-bold rounded-3xl px-12 py-2.5"
              type="button"
              onClick={() => {
                handleModal(false);
                handleCodeModal(true);
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
