export const MenuItem = [
  {
    name: "Primary-Level (Play group to 5th)",
    value: "school",
  },
  {
    name: "Middle-Level (6th to 8th)",
    value: "school",
  },
  {
    name: "High-School (9th/10th)",
    value: "school",
  },
  {
    name: "Intermediate/Collage (11th/12th)",
    value: "college",
  },
  {
    name: "Bachelors Level/University",
    value: "university",
  },
  {
    name: "Masters Level/University",
    value: "university",
  },
  {
    name: "Employee",
    value: "employee",
  },
  {
    name: "Guardian-Primary-Level (Play group to 5th)",
    value: "gaurdian-school",
  },
  {
    name: "Guardian-Middle-Level (6th to 8th)",
    value: "gaurdian-school",
  },
  {
    name: "Guardian-High-School (9th/10th)",
    value: "gaurdian-school",
  },
  {
    name: "Guardian-Intermediate/Collage (11th/12th)",
    value: "gaurdian-college",
  },
  {
    name: "Guardian-Bachelors Level/University",
    value: "gaurdian-university",
  },
  {
    name: "Guardian-Masters Level/University",
    value: "gaurdian-university",
  },
];
