import { useState } from "react";
import { MdModeEditOutline } from "react-icons/md";
import HomeTables from "../../tables/HomeTables";
import ScheduleModal from "./ScheduleModal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import previewPDF from "../../../assets/images/schedule.png";
import ReplicateScheduleModal from "./ReplicateScheduleModal";

const ScheduleTab = ({
  create,
  setShowPDF,
  showPDF,
  setEdit,
  onEdit,
  Content,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [replicateModal, setReplicateModal] = useState(false);
  const isCheckbox = true;
  const columns = ["Time", "Route No", "Vehicle Reg", "Drivers"];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Schedule Report";
    const headers = [columns];
    const pdfData = Content.map((val) => [
      val.time,
      val.route_no,
      val.vehicle_reg,
      val.driver,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: pdfData,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Schedule Report.pdf");
  };
  return (
    <div className="flex flex-col h-full">
      {showPDF ? (
        <div className="shadow-all-rounded rounded-xl flex flex-col overflow-y-auto">
          <img src={previewPDF} />
          {/* <HomeTables
            Content={Content}
            columns={columns}
            create={create}
          /> */}
          <div className="bg-placeholder/50 p-6  rounded-b-xl flex flex-col sm:flex-row space-y-3 md:space-y-0 items-end justify-end">
            <div className="bg-green text-white w-36 text-center py-2 px-4 rounded-3xl shadow-all-rounded">
              <button onClick={exportPDF}>Create PDF</button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <HomeTables
            Content={Content}
            columns={columns}
            isCheckbox={isCheckbox}
            create={create}
          />
          <div className="bg-placeholder/50 p-4 rounded-b-xl flex flex-col sm:flex-row space-y-3 md:space-y-0 items-center justify-between">
            {!(create === "true") && (
              <div
                className="bg-light-orange text-white cursor-pointer w-36 text-center py-2 px-4 rounded-3xl shadow-all-rounded flex items-center space-x-1 justify-center"
                onClick={() => {
                  setIsOpen(true);
                  setEdit(false);
                }}
              >
                <MdModeEditOutline className="text-xl" />
                <p>Modify</p>
              </div>
            )}
            <div
              className={`flex flex-col sm:flex-row items-center sm:space-x-11 ${
                create === "true" && "ml-auto"
              }`}
            >
              <div className="bg-white text-green min-w-36 text-center py-2 px-4 rounded-3xl shadow-all-rounded">
                {create === "true" ? (
                  <button onClick={() => setShowPDF(true)}>
                    Preview in PDF
                  </button>
                ) : (
                  <button onClick={() => setReplicateModal(true)}>
                    Replicate Schedule
                  </button>
                )}
              </div>
              <div className="bg-green text-white w-36 text-center py-2 px-4 rounded-3xl shadow-all-rounded">
                {create === "true" ? (
                  <button>Publish</button>
                ) : (
                  <button onClick={exportPDF}>Create PDF</button>
                )}
              </div>
            </div>
          </div>
          <ScheduleModal
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            onEdit={onEdit}
          />
          <ReplicateScheduleModal
            replicateModal={replicateModal}
            setReplicateModal={setReplicateModal}
          />
        </>
      )}
    </div>
  );
};
export default ScheduleTab;
