import React from "react";
import { Route, Routes } from "react-router-dom";
import Drivers from "../pages/drivers/Drivers";
import ForgotPassword from "../pages/forgot-password/ForgotPassword";
import Help from "../pages/help/Help";
import Home from "../pages/home/Home";
import Schedule from "../pages/home/schedule/Schedule";
import Login from "../pages/login/Login";
import Registration from "../pages/registration/Registration";
import Rout from "../pages/routes/Rout";
import Vehicles from "../pages/vehicles/Vehicles";
import LogReport from "../pages/home/log-report/LogReport";
import RoutesDirections from "./types";
import LogReportPdf from "../pages/home/log-report/LogReportPdf";
import { TransportUser } from "../pages/home/tranporte-user/TransportUser";
import ActiveVehicle from "../pages/home/active-vehicle/ActiveVehicle";
import LiveLocation from "../pages/home/active-vehicle/LiveLocation";
import Wallet from "../pages/wallet/Wallet";
import Profile from "../pages/profile/Profile";
import ApprovedUser from "../pages/home/tranporte-user/ApprovedUser";
import QR from "../pages/qr/QR";
import DeactivateAccount from "../pages/deactivateAccount/DeactivateAccount";
import DisapproveUserTable from "../components/home/transport-user/DisappoveUserTable";
import PastUser from "../components/home/transport-user/PastUser";
import ProtectedRoutes from "./ProtectedRoutes";

const RoutePaths = () => {
  return (
    <Routes>
      <Route path={RoutesDirections.Register} element={<Registration />} />
      <Route
        path={RoutesDirections.ForgotPassword}
        element={<ForgotPassword />}
      />
      <Route path={RoutesDirections.Help} element={<Help />} />
      <Route path={RoutesDirections.Login} element={<Login />} />
      <Route element={<ProtectedRoutes />}>
        <Route path={RoutesDirections.Home} element={<Home />} />
        <Route path={RoutesDirections.LogReport} element={<LogReport />} />
        <Route
          path={RoutesDirections.LogReportPdf}
          element={<LogReportPdf />}
        />
        <Route
          path={RoutesDirections.TransportUser}
          element={<TransportUser />}
        />
        <Route
          path={RoutesDirections.ActiveVehicle}
          element={<ActiveVehicle />}
        />
        <Route
          path={RoutesDirections.ApprovedUser}
          element={<ApprovedUser />}
        />
        <Route
          path={RoutesDirections.LiveLocation}
          element={<LiveLocation />}
        />
        <Route path={RoutesDirections.Wallet} element={<Wallet />} />
        <Route path={RoutesDirections.Profile} element={<Profile />} />
        <Route path={RoutesDirections.Schedule} element={<Schedule />} />
        <Route path={RoutesDirections.Drivers} element={<Drivers />} />
        <Route path={RoutesDirections.Vehicles} element={<Vehicles />} />
        <Route path={RoutesDirections.Routes} element={<Rout />} />
        <Route path={RoutesDirections.QR} element={<QR />} />
        <Route
          path={RoutesDirections.DeactivateAccount}
          element={<DeactivateAccount />}
        />
        <Route
          path={RoutesDirections.DisapprovedUser}
          element={<DisapproveUserTable />}
        />
        <Route path={RoutesDirections.PastUser} element={<PastUser />} />
      </Route>
    </Routes>
  );
};

export default RoutePaths;
