import { IoClose } from "react-icons/io5";
import Modal from "react-modal";
import Input from "../../input/Input";
import UserImg from "../../../assets/images/svgs/user.svg";
import CardImg from "../../../assets/images/svgs/card.svg";
import PhoneImg from "../../../assets/images/svgs/phone.svg";
import useWindowSize from "../../../utils/useWindowSize";
import CameraBtn from "../../../assets/images/svgs/camera.svg";
import CardPic from "../../../assets/images/card-pic.png";
import MapPic from "../../../assets/images/map-pic.png";

export default function EmployeeForm({ modalOpen, setModalOpen }) {
  const { width } = useWindowSize();

  const customStyles = {
    content: {
      top: "95px",
      left: "auto",
      right: "10px",
      bottom: "auto",
      maxHeight: "80vh",
      height: "100%",
      width:
        width < 768
          ? "23rem"
          : width > 768 && width < 1024
          ? "35rem"
          : width >= 1024 && "45rem",
      boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.25)",
      borderRadius: "20px",
      padding: "0px",
      overflowY: "none",
    },
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Modal isOpen={modalOpen} onRequestClose={closeModal} style={customStyles}>
      <div className="h-full flex flex-col justify-between relative pt-4">
        <div className="flex flex-row justify-between items-center sticky w-full">
          <div onClick={closeModal} className="cursor-pointer absolute left-3">
            <IoClose className="font-bold text-3xl" />
          </div>
          <div className="font-bold text-xl md:text-3xl mx-auto">Employee</div>
        </div>
        <div className="h-full my-12 py-4">
          <div className="flex flex-row items-center justify-between px-6 max-[768px]:flex-col max-[768px]:gap-2">
            <h1 className="font-bold text-lg text-slate-600">
              Employee Information
            </h1>
            <div className="p-3 bg-white shadow-all-rounded h-20 w-20 rounded-full cursor-pointer flex items-center justify-center">
              <img src={CameraBtn} alt="camera" className="h-4" />
              <input type="file" className="hidden" />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-10 px-6 mb-10">
            <div className="col-span-6 max-[768px]:col-span-12">
              <Input
                name="Full Name"
                type="text"
                placeholder="name"
                icon={UserImg}
              />
            </div>
            <div className="col-span-6 max-[768px]:col-span-12">
              <Input name="Employeed Id" type="text" placeholder="id" />
            </div>
            <div className="col-span-6 max-[768px]:col-span-12">
              <Input
                name="Phone Number"
                type="number"
                placeholder="+01 112 xxx xxx"
                icon={PhoneImg}
              />
            </div>
            <div className="col-span-6 max-[768px]:col-span-12">
              <Input name="Department" type="text" placeholder="department" />
            </div>
            <div className="col-span-6 max-[768px]:col-span-12">
              <Input
                name="CNIC Number"
                type="number"
                placeholder="CNIC number, e.g : +01 112 xxx xxx"
                icon={CardImg}
              />
            </div>

            <div className="col-span-6 max-[768px]:col-span-12">
              <Input name="Designation" type="text" placeholder="designation" />
            </div>
            <div className="col-span-6 max-[768px]:col-span-12">
              <Input name="Home Address" type="text" placeholder="x-x-x-x" />
            </div>
            <div className="col-span-6 max-[768px]:col-span-12">
              <Input name="Institute Name" type="text" placeholder="x-x-x-x" />
            </div>
            <div className="col-span-12">
              <Input name="Pickup Address" type="text" placeholder="x-x-x-x" />
            </div>
            <div className="col-span-6 max-[768px]:col-span-12">
              <h1 className="font-bold text-sm sm:text-lg mb-4">Map</h1>

              <img src={MapPic} alt="" className="w-full" />
            </div>
            <div className="col-span-6 max-[768px]:col-span-12">
              <h1 className="font-bold text-sm sm:text-lg mb-4">
                Employee Card
              </h1>

              <img src={CardPic} alt="" />
            </div>

            <div className="col-span-6 p-8 border border-black rounded-2xl max-[768px]:col-span-12">
              <div className="mb-6">
                <h1 className="text-lg font-bold mb-2">
                  Transport Starting Date
                </h1>
                <input
                  type="date"
                  className="border shadow-all-rounded px-3 py-2 rounded-xl"
                />
              </div>
              <div>
                <h1 className="text-lg font-bold mb-2">
                  Transport Ending Date
                </h1>
                <input
                  type="date"
                  className="border shadow-all-rounded px-3 py-2 rounded-xl"
                />
              </div>
            </div>
          </div>
          <div className="bg-placeholder/50 p-5 rounded-b-xl flex flex-col sm:flex-row space-y-3 md:space-y-0 justify-end mt-4">
            <div className={`flex flex-col sm:flex-row items-center gap-6`}>
              <div className="bg-light-orange text-white w-36 text-center py-1 px-4 rounded-3xl shadow-all-rounded flex items-center space-x-1 justify-center cursor-pointer">
                <p>Meet</p>
              </div>

              <div className="bg-red text-white min-w-36 text-center py-1 px-4 rounded-3xl shadow-all-rounded cursor-pointer">
                Disapprove
              </div>
              <div className="bg-green text-white min-w-36 text-center py-1 px-4 rounded-3xl shadow-all-rounded cursor-pointer">
                Aprrove
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
