import { useState, useEffect } from "react";
import DriverModal from "./DriverModal";
import Table from "../tables/Table";
import driver from "../../services/driver";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DriverTab = () => {
  const [modalIsOpen, setAddOpen] = useState(false);
  const columnNames = ["Title", "Cnic", "License No", "Approval Date"];
  const tableColumn = ["name", "cnic", "license_no", "created_at"];
  const [TableContent, setData] = useState([{}]);

  useEffect(() => {
    async function fetchAllDriver() {
      const response = await driver.fetchDrivers();
      if (!response.exception) {
        setData(response.data);
      } else {
        toast.error(response.errors, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
    fetchAllDriver();
  }, []);

  return (
    <div className="h-full overflow-y-auto">
      <Table
        TableContent={TableContent}
        setAddOpen={setAddOpen}
        columnNames={columnNames}
        tableColumn={tableColumn}
      />
      <DriverModal modalIsOpen={modalIsOpen} setAddOpen={setAddOpen} edit />
    </div>
  );
};

export default DriverTab;
