const NotificationItem = ({ title, detail, icon, time, earning }) => {
  return (
    <div className="py-4 border-b-2 flex flex-row justify-between items-center">
      <div className="flex flex-row items-center gap-2">
        <div
          className={`w-14 h-14 ${
            earning === true
              ? "rounded-3xl bg-yellow-300"
              : "bg-rose-600 rounded-full"
          }  flex items-start justify-center flex-row`}
        >
          <img src={icon} alt="icon-pic" className={`${earning === true ? 'm-5':'mt-1'} `} />
        </div>

        <div>
          <h2 className="font-bold">{title}</h2>
          <span className="text-sm text-slate-500 font-medium">{detail}</span>
        </div>
      </div>
      <span className="text-sm text-slate-500 font-semibold">{time}m ago</span>
    </div>
  );
};

export default NotificationItem;
