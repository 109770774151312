import { useNavigate } from "react-router-dom";
import RoutesDirections from "../../routes/types";

export default function AccountDeactivateConfirmModal({
  handleConfirmModal,
  confirmModalIsOpen,
}) {
  const navigate = useNavigate();

  return (
    <div
      className={`${
        confirmModalIsOpen === true ? "block" : "hidden"
      } fixed top-80 left-80 z-50  w-96 p-4 overflow-x-hidden overflow-y-auto backdrop-blur-sm`}
    >
      <div className="relative w-full h-full max-w-2xl md:h-auto">
        <div className="relative bg-[#00000040] rounded-lg shadow pb-1">
          <div className="flex justify-between p-4 flex-col items-center px-8">
            <h3 className="text-xl font-extrabold text-black text-center">
              Please confirm your account deactivation. To reactivate account,
              you may contact the STOPPICK support team.
            </h3>
          </div>
          <div className="flex justify-center mx-10 mb-4 pt-6">
            <button
              className="bg-red text-white text-xl font-bold rounded-3xl px-14 py-2.5"
              type="button"
              onClick={() => {
                navigate(RoutesDirections.Login);
                handleConfirmModal(false);
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
