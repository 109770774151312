export const Awaiting_Approvals = [
  {
    name: "Ghafoor",
    rollNo: 101,
    class: "ICS",
    gaurdians: 1,
    status: "pending",
  },
  {
    name: "Ghafoor",
    rollNo: 134,
    class: "ICS",
    gaurdians: 3,
    status: "meet personaly",
  },
  {
    name: "Ghafoor",
    rollNo: 104,
    class: "ICS",
    gaurdians: 2,
    status: "meet",
  },
];

export const DisApprovals = [
  {
    name: "Ghafoor",
    rollNo: 109,
    class: "ICS",
    gaurdians: 2,
  },
  {
    name: "Ghafoor",
    rollNo: 108,
    class: "ICS",
    gaurdians: 3,
  },
  {
    name: "Ghafoor",
    rollNo: 102,
    class: "ICS",
    gaurdians: 2,
  },
  {
    name: "Ghafoor",
    rollNo: 102,
    class: "ICS",
    gaurdians: 2,
  },
  {
    name: "Ghafoor",
    rollNo: 102,
    class: "ICS",
    gaurdians: 2,
  },
  {
    name: "Ghafoor",
    rollNo: 102,
    class: "ICS",
    gaurdians: 2,
  },
];

export const Approved_User = [
  {
    name: "Ghafoor",
    rollNo: 101,
    class: "metric",
    gaurdians: 2,
  },
  {
    name: "Ghafoor",
    rollNo: 101,
    class: "metric",
    gaurdians: 2,
  },
  {
    name: "Ghafoor",
    rollNo: 101,
    class: "metric",
    gaurdians: 2,
  },
  {
    name: "Ghafoor",
    rollNo: 101,
    class: "metric",
    gaurdians: 2,
  },
  {
    name: "Ghafoor",
    rollNo: 101,
    class: "metric",
    gaurdians: 2,
  },
  {
    name: "Ghafoor",
    rollNo: 101,
    class: "metric",
    gaurdians: 2,
  },
  {
    name: "Ghafoor",
    rollNo: 101,
    class: "metric",
    gaurdians: 2,
  },
  {
    name: "Ghafoor",
    rollNo: 101,
    class: "metric",
    gaurdians: 2,
  },
];
