import React from "react";
import { useNavigate } from "react-router-dom";

const HomeItem = ({ text, icon, link }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (link) navigate(link);
  };

  return (
    <div
      className="max-w-auto w-full flex justify-between items-center border px-5 py-2 shadow-all-rounded rounded-3xl cursor-pointer"
      onClick={handleClick}
    >
      <p className="text-dark-gray font-bold text-lg md:text-3xl">{text}</p>
      <img
        src={icon}
        alt={`${text}-icon`}
        className="object-scale-down h-6 w-8 md:object-contain md:h-16 md:w-16 mx-4 sm:mx-0 justify-end"
      />
    </div>
  );
};

export default HomeItem;
