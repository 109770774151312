import Modal from "react-modal";
// import Input from "../../input/Input";
import useWindowSize from "../../../utils/useWindowSize";

export default function ReplicateScheduleModal({
  replicateModal,
  setReplicateModal,
}) {
  const { width } = useWindowSize();

  const customStyles = {
    content: {
      top: "95px",
      left: "auto",
      right: "10px",
      bottom: "auto",
      width:
        width < 768
          ? "23rem"
          : width > 768 && width < 1024
          ? "35rem"
          : width >= 1024 && "45rem",
      boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.25)",
      borderRadius: "20px",
      padding: "0px",
    },
  };

  const closeModal = () => {
    setReplicateModal(false);
  };

  return (
    <div>
      <Modal
        isOpen={replicateModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="p-5 h-[76vh] overflow-y-auto">
          <div className="flex items-center mb-8 top-0">
            <div className="font-bold text-xl md:text-4xl flex-grow text-center">
              Replicate Schedule
            </div>
          </div>
          <div className="mt-20">
            <div className="max-w-lg mx-auto ">
              <div className="font-bold text-sm sm:text-xl mb-4">
                {" "}
                Please select the date for which you want to replicate this schedule.
              </div>
              <input
                type="date"
                className="py-2 px-4 w-auto sm:w-auto text-sm sm:text-lg flex items-center border border-placeholder rounded-[1.25rem] mb-4 mt-4 justify-between"
              />
              <div className="font-bold text-sm sm:text-xl mb-4">
                {" "}
                Please note that the replicate schedule will show in the create
                schedule section under the selected date and you need to publish
                it.
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end rounded-b-xl gap-8 px-3 py-5 bg-placeholder/50">
          <div
            onClick={closeModal}
            className="bg-white border flex items-center justify-center text-sm text-black font-semibold rounded-2xl px-6 py-2 shadow-all-rounded cursor-pointer"
          >
            <p className=" whitespace-nowrap">Cancel</p>
          </div>

          <div
            onClick={closeModal}
            className="bg-green flex items-center justify-center text-sm text-white font-semibold rounded-2xl px-8 py-2 shadow-all-rounded cursor-pointer"
          >
            <p className=" whitespace-nowrap">Replicate</p>
          </div>
        </div>
      </Modal>
    </div>
  );
}
