import { useState } from "react";
import { Approved_User } from "../../../data/transportUserData";
import TransportUserTables from "../../tables/TransportUserTables";
const ApprovedUserTable = ({ setShowSchoolForm, setShowPDF }) => {
  const columns = [
    "Title",
    "Roll No / Emp ID",
    "Class / Department",
    "Guardians",
  ];
  return (
    <div style={{ height: "85%" }}>
      <div className="flex flex-col max-w-full justify-between shadow-all-rounded rounded-xl h-full">
        <div className="overflow-x-auto">
          <div className="w-full inline-blocks align-middle">
            <div className="overflow-hidden h-full">
              <TransportUserTables
                columns={columns}
                TableData={Approved_User}
              />
            </div>
          </div>
        </div>
        <div className="bg-placeholder/50 p-5 rounded-b-xl flex flex-row space-y-3 md:space-y-0 items-center justify-end">
          <div className="flex flex-col sm:flex-row items-center sm:space-x-3">
            <div className="bg-red text-white text-lg min-w-36 text-center py-3 px-4 rounded-3xl shadow-all-rounded cursor-pointer">
              Remove
            </div>
            <div
              className="bg-green text-white text-lg min-w-36 text-center py-3 px-4 rounded-3xl shadow-all-rounded cursor-pointer"
              onClick={() => setShowPDF(true)}
            >
              Create PDF
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovedUserTable;
