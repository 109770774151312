import Layout from "../../layout/Layout";

import Help from "../../components/help/Help";

const HelpPage = () => {
  return (
    <Layout>
      <Help />
    </Layout>
  );
};

export default HelpPage;
