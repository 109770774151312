import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiTwotoneFilter, AiOutlinePlus } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import Layout from "../../../layout/Layout";
import { DisApprovals } from "../../../data/transportUserData";
import RoutesDirections from "../../../routes/types";
import CustomMenu from "../../../components/shared/CustomMenu";
import { MenuItem } from "../../../data/FormMenuData";
import EmployeeForm from "../../../components/forms/EmployeeForm";
import UniversityForm from "../../../components/forms/UniversityForm";
import SchoolForm from "../../../components/forms/SchoolForm";
import CollegeForm from "../../../components/forms/CollegeForm";
import CollegeGaurdian from "../../../components/forms/GaurdianForm/CollegeGaurdian";
import SchoolGaurdian from "../../../components/forms/GaurdianForm/SchoolGaurdian";
import UniversityGaurdian from "../../../components/forms/GaurdianForm/UniversityGaurdian";
import TransportUserTables from "../../tables/TransportUserTables";
import UserFilterModal from "../../../components/home/transport-user/UserFilterModal";

const PastUser = () => {
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [selectedApprovals, setSelectedApprovals] = useState(
    "/transport-user/past-user"
  );
  const [showFormMenu, setShowFormMenu] = useState(false);
  const [showSchoolForm, setShowSchoolForm] = useState(false);
  const [showCollegeForm, setShowCollegeForm] = useState(false);
  const [showUniversityForm, setShowUniversityForm] = useState(false);
  const [showEmployeeForm, setShowEmployeeForm] = useState(false);
  const [showCollegeGaurdianForm, setShowCollegeGaurdianForm] = useState(false);
  const [showSchoolGaurdianForm, setShowSchoolGaurdianForm] = useState(false);
  const [showUniversityGaurdianForm, setShowUniversityGaurdianForm] =
    useState(false);

  useEffect(() => {
    navigate(selectedApprovals);
    // eslint-disable-next-line
  }, [selectedApprovals]);

  const columns = [
    "Title",
    "Roll No / Emp ID",
    "Class / Department",
    "Gaurdians",
  ];

  const showSelectedForm = (selectedFormValue) => {
    setShowFormMenu(!showFormMenu);

    switch (selectedFormValue) {
      case "school":
        setShowSchoolForm(true);
        break;
      case "college":
        setShowCollegeForm(true);
        break;
      case "university":
        setShowUniversityForm(true);
        break;
      case "employee":
        setShowEmployeeForm(true);
        break;
      case "gaurdian-school":
        setShowSchoolGaurdianForm(true);
        break;
      case "gaurdian-college":
        setShowCollegeGaurdianForm(true);
        break;
      case "gaurdian-university":
        setShowUniversityGaurdianForm(true);
        break;
      default:
        break;
    }
  };

  return (
    <Layout>
      <div className="w-auto sm:max-w-6xl mx-auto" style={{ height: "85%" }}>
        <div className="flex flex-col sm:flex-row flex-wrap gap-4 mb-5">
          <div className="flex flex-grow items-center border border-black rounded-xl w-fit">
            <BsSearch size={25} className="ml-3 mr-1" />
            <input
              className="px-3 py-2 rounded-xl text-xl outline-0 w-full"
              type="search"
              placeholder="Search"
            />
            <AiTwotoneFilter
              size={25}
              onClick={() => setShowFilter(true)}
              className="mr-3 ml-1 cursor-pointer"
            />
          </div>
          <div className="flex flex-row justify-between items-center">
            <div className="mr-4">
              <div
                onClick={() => setShowFormMenu(!showFormMenu)}
                className="bg-green flex items-center justify-center text-xl text-white font-semibold rounded-2xl px-3 py-2 shadow-all-rounded cursor-pointer"
              >
                <AiOutlinePlus className="font-semibold mr-3" />
                <p className=" whitespace-nowrap">Add</p>
              </div>
              {showFormMenu && (
                <div className="absolute z-40 md:right-56 right-40 mt-2">
                  <CustomMenu
                    MenuItem={MenuItem}
                    showSelectedForm={showSelectedForm}
                  />
                </div>
              )}
            </div>

            <select
              onChange={(e) => setSelectedApprovals(e.target.value)}
              value={selectedApprovals}
              className="border shadow-all-rounded px-3 py-2 rounded-xl outline-0 cursor-pointer font-bold text-[#606060] bg-light-yellow"
            >
              <option
                value={RoutesDirections.ApprovedUser}
                className="font-bold text-black"
              >
                Approved Users
              </option>
              <option
                value={RoutesDirections.TransportUser}
                className="font-bold text-black"
              >
                Awaiting Approvals
              </option>
              <option
                value={RoutesDirections.DisapprovedUser}
                className="font-bold text-black"
              >
                Disapproved Users
              </option>
              <option
                value={RoutesDirections.PastUser}
                className="font-bold text-black"
              >
                Past Users
              </option>
            </select>
          </div>
        </div>
        <div className="flex flex-col h-full max-w-full justify-between shadow-all-rounded rounded-xl">
          <div className="overflow-x-auto">
            <div className="w-full inline-block align-middle">
              <div className="overflow-hidden">
                <TransportUserTables
                  columns={columns}
                  TableData={DisApprovals}
                />
              </div>
            </div>
          </div>
          <div className="bg-placeholder/50 p-5 rounded-b-xl flex flex-col sm:flex-row space-y-3 md:space-y-0 items-center justify-end">
            <div
              className={`flex flex-col sm:flex-row items-center sm:space-x-3`}
            >
              <div className="bg-red text-white min-w-36 text-center py-3 px-8 rounded-3xl shadow-all-rounded cursor-pointer text-lg">
                Delete
              </div>
              <div className="bg-custom-green text-white min-w-36 text-center py-3 px-4 rounded-3xl shadow-all-rounded cursor-pointer text-lg">
                Re-Approve
              </div>
            </div>
          </div>
          <UserFilterModal modalIsOpen={showFilter} setIsOpen={setShowFilter} />
          <EmployeeForm
            modalOpen={showEmployeeForm}
            setModalOpen={setShowEmployeeForm}
          />
          <UniversityForm
            modalOpen={showUniversityForm}
            setModalOpen={setShowUniversityForm}
          />
          <CollegeForm
            modalOpen={showCollegeForm}
            setModalOpen={setShowCollegeForm}
          />
          <SchoolForm
            modalOpen={showSchoolForm}
            setModalOpen={setShowSchoolForm}
          />
          <CollegeGaurdian
            modalOpen={showCollegeGaurdianForm}
            setModalOpen={setShowCollegeGaurdianForm}
          />
          <SchoolGaurdian
            modalOpen={showSchoolGaurdianForm}
            setModalOpen={setShowSchoolGaurdianForm}
          />
          <UniversityGaurdian
            modalOpen={showUniversityGaurdianForm}
            setModalOpen={setShowUniversityGaurdianForm}
          />
        </div>
      </div>
    </Layout>
  );
};

export default PastUser;
