import Input from "../input/Input";
import HelpImage from "../../assets/images/help.png";

const Help = () => {
  return (
    <div>
      <div className="grid grid-cols-12 items-center">
        <div className="py-10 flex flex-col items-center col-span-7 px-10 max-[970px]:col-span-8  max-[900px]:col-span-12">
          <div className="flex flex-col my-2 items-center gap-2 max-w-full w-full">
            <h1 className="text-3xl font-bold">Help</h1>
            <span className="text-slate-400 text-sm">How can we help you?</span>
          </div>

          <div className="flex flex-col max-w-full w-full px-4 max-[400px]:px-0">
            <Input name="Full Name" placeholder="Your name" type="text" />
            <Input
              name="Phone Number"
              placeholder="Your phone number, e.g : +01 112 xxx xxx"
              type="number"
            />
            <Input name="Email" placeholder="Enter your email" type="text" />
            <div className="flex flex-col">
              <p className="font-bold text-sm sm:text-lg mb-4">Message</p>
              <textarea
                className="border border-placeholder rounded-[1.25rem] p-4 text-sm sm:text-[0.9375rem] outline-none mb-4 flex-grow resize-none"
                placeholder="Type Here"
                rows={5}
              />
            </div>
            <button className="rounded-xl shadow-md w-100 py-2 mt-2 text-white bg-light-orange hover:bg-orange-400">
              Send Message
            </button>
          </div>
        </div>
        <div className="col-span-5 max-[970px]:col-span-4 max-[900px]:col-span-12 max-[900px]:flex max-[900px]:items-center max-[900px]:justify-center max-[900px]:order-first">
          <img
            src={HelpImage}
            alt="help"
            className="max-[900px]:max-w-xs h-80 w-max"
          />
        </div>
      </div>
    </div>
  );
};

export default Help;
