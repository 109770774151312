import ArrowIcon from "../../assets/icons/round-arrow.svg";
import Layout from "../../layout/Layout";

const QR = () => {
  return (
    <Layout>
      <div className="h-[72vh] flex justify-center items-center p-10">
        <div className="max-w-xl w-full sm:h-[380px] shadow-all-rounded rounded-xl mt-20 flex flex-col justify-between h-auto">
          <div className="h-full flex justify-center items-center w-80">
            <h1 className="sm:max-w-[250px] font-bold text-xl">
              Our system will generate a unique code for the first time
              automatically, which will be shown here.
            </h1>
          </div>

          <div className="bg-placeholder/50 p-5 rounded-b-xl flex space-y-3 md:space-y-0 items-end justify-end">
            <div className="bg-green text-white text-center px-1 py-2 md:px-4 rounded-3xl shadow-all-rounded flex flex-row items-center gap-3 cursor-pointer">
              <img src={ArrowIcon} alt="arror" />
              <span className="font-bold text-base md:text-lg">Share Code</span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default QR;
