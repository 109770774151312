import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { BsSearch } from "react-icons/bs";
import ActiveVehicleTable from "../../../components/home/active-vehicle/ActiveVehicleTable";
import LocationImg from "../../../assets/icons/location-icon.svg";
import Layout from "../../../layout/Layout";
import ActiveVehicleLocationModal from "../../../components/home/active-vehicle/ActiveVehicleLocationModal";
import RoutesDirections from "../../../routes/types";

const ActiveVehicle = () => {
  const navigate = useNavigate();
  const [showVehicleLocation, setShowVehicleLocation] = useState(false);

  return (
    <Layout>
      <div className="max-w-6xl mx-auto h-full">
        <div className="flex flex-col sm:flex-row flex-wrap gap-4 mb-5">
          <div className="flex flex-grow items-center border rounded-xl border-black">
            <BsSearch size={25} className="ml-3 mr-1" />
            <input
              className="px-3 py-2 rounded-xl text-xl outline-0 w-full"
              type="search"
              placeholder="Search"
            />
          </div>
          <div
            className={`flex flex-col sm:flex-row items-center sm:space-x-11 cursor-pointer`}
            //TODO: Map required Google api key
            onClick={() => navigate(RoutesDirections.LiveLocation)}
          >
            <div className="bg-green shadow-all-rounded flex flex-row gap-2 text-white px-4 py-2 rounded-2xl cursor-pointer">
              <img src={LocationImg} alt="icon" />
              <h1 className="text-lg"> Live Location</h1>
            </div>
          </div>
        </div>
        <div
          className="shadow-all-rounded rounded-xl"
          style={{ height: "85%" }}
        >
          <ActiveVehicleTable setShowVehicleLocation={setShowVehicleLocation} />
        </div>
        <ActiveVehicleLocationModal
          showVehicleLocation={showVehicleLocation}
          setShowVehicleLocation={setShowVehicleLocation}
        />
      </div>
    </Layout>
  );
};

export default ActiveVehicle;
