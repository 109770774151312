const IconButton = ({ icon, text }) => {
  return (
    <div className="w-100 h-100 flex flex-row px-3 py-3 items-center justify-center gap-2 rounded-xl bg-gray-900 cursor-pointer max-[1040px]:my-2">
      <h1 className="text-white text-md font-semibold">{text}</h1>
      <img src={icon} alt="icon" />
    </div>
  );
};

export default IconButton;
