import React, { useEffect, useRef, useState } from "react";
import Logo from "../../assets/images/svgs/logo.svg";
import HelpImg from "../../assets/images/svgs/help.svg";
import { Link } from "react-router-dom";
import RoutesDirections from "../../routes/types";
import NotificationImg from "../../assets/images/svgs/notification.svg";
import CameraBtn from "../../assets/images/svgs/camera.svg";
import { RiMenu2Line, RiMenu3Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { selectHam, setHam } from "../../redux/features/navbarReducer";
import useWindowSize from "../../utils/useWindowSize";
import NotificationMenu from "../shared/NotificationMenu";
import managerService from "../../services/manager";
import { setProfilePicture } from "../../redux/features/managerReducer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Header = ({ auth }) => {
  const hiddenFileInput = useRef();
  const dispatch = useDispatch();
  const value = useSelector(selectHam);
  const { width } = useWindowSize();
  const [showNotificationMenu, setShowNotificationMenu] = useState(false);
  const [profilePictureURL, setProfilePictureURL] = useState(CameraBtn);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    setProfilePictureURL(fileUploaded);
    const response = await managerService.setProfilePicture(fileUploaded);
    if (!response.exception) {
      dispatch(setProfilePicture(response.data.picture));
      toast.success("Profile Picture Updated", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.error(response.errors, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  useEffect(() => {
    async function profilePicture() {
      const response = await managerService.getProfilePicture(
        localStorage.getItem("token")
      );
      if (!response.exception) {
        if (response.data.picture != null) {
          setProfilePictureURL(response.data.picture);
          dispatch(setProfilePicture(profilePictureURL));
        }
      }
    }
    profilePicture();
  }, [profilePictureURL, dispatch]);

  useEffect(() => {
    if (width > 768) {
      dispatch(setHam(true));
    } else if (width <= 768) {
      dispatch(setHam(false));
    }
  }, [width, dispatch]);

  return (
    <div className="bg-white shadow-all-rounded h-20 flex items-center justify-between pl-6 pr-7 sticky top-0 w-auto z-10">
      <div className="flex items-center text-sm sm:text-md md:text-xl lg:text-3xl relative">
        {auth && width <= 768 && (
          <div
            className="cursor-pointer"
            onClick={() => dispatch(setHam(!value))}
          >
            {!value ? <RiMenu2Line /> : <RiMenu3Line />}
          </div>
        )}
        <img
          src={Logo}
          alt="logo"
          className="object-scale-down h-10 w-8 sm:h-16 sm:w-14"
        />
        <p className="text-black font-bold">Stop Pick</p>
        <p className="text-black font-bold text-sm absolute sm:-right-16 sm:top-0 -right-10 -top-2">
          (Manager)
        </p>
      </div>
      <div className="flex space-x-4 md:space-x-[72px] md:mr-12 items-center">
        {auth ? (
          <>
            <div className="relative">
              <div
                className="flex items-center space-x-1 cursor-pointer"
                onClick={() => setShowNotificationMenu(!showNotificationMenu)}
              >
                <img
                  src={NotificationImg}
                  alt="notification-icon"
                  className=""
                />
                <p className="text-xs md:text-lg font-semibold">
                  Notifications
                </p>
              </div>
              {showNotificationMenu && (
                <div className="absolute -bottom-12 -left-16 shadow-2xl">
                  <NotificationMenu />
                </div>
              )}
            </div>

            <div className="flex items-center flex-col">
              <div
                className="flex items-center justify-center p-3 drop-shadow-xl h-full w-full rounded-full cursor-pointer bg-white overflow-hidden"
                onClick={handleClick}
              >
                <img
                  src={profilePictureURL}
                  alt="camera"
                  className="h-7 w-7 object-contain  rounded-full"
                />
                <input
                  type="file"
                  className="hidden"
                  onChange={handleChange}
                  ref={hiddenFileInput}
                />
              </div>
              <p className="text-xs md:text-base font-semibold">Me</p>
            </div>
          </>
        ) : (
          <>
            <Link to={RoutesDirections.Help}>
              <div className="flex items-center cursor-pointer">
                <img
                  src={HelpImg}
                  alt="help"
                  className="h-5 w-5 md:h-8 md:w-8"
                />
                <p className="text-sm md:text-lg font-semibold">Help</p>
              </div>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
