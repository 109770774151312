import Modal from "react-modal";
import useWindowSize from "../../../utils/useWindowSize";

const UserFilterModal = ({ modalIsOpen, setIsOpen }) => {
  const { width } = useWindowSize();

  const customStyles = {
    content: {
      top: "15%",
      left: "25%",
      right: "25%",
      bottom: "5%",
      width:
        width < 768
          ? "23rem"
          : width > 768 && width < 1024
          ? "30rem"
          : width >= 1024 && "38rem",
      boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.25)",
      borderRadius: "20px",
      padding: "0px",
    },
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="p-5 h-auto">
          <div className="flex items-center mb-8 top-0 sticky">
            <div className="font-bold text-xl md:text-3xl flex-grow text-center">
              Filters
            </div>
          </div>
          <div className="mt-20 overflow-y-auto">
            <div className="max-w-lg mx-16">
              <label className="font-bold text-sm sm:text-xl my-3 block">
                Transport Facility Starting Date
              </label>
              <div className="p-1 shadow-all-rounded rounded-2xl">
                <input type="date" className="w-full" />
              </div>

              <label className="font-bold text-sm sm:text-xl my-3 block">
                Transport Facility Ending Date
              </label>
              <div className="p-1 shadow-all-rounded rounded-2xl">
                <input type="date" className="w-full" />
              </div>

              <label className="font-bold text-sm sm:text-xl my-3 block">
                Class Timing
              </label>
              <div className="p-1 shadow-all-rounded rounded-2xl">
                <input type="time" className="w-full" />
              </div>

              <label className="font-bold text-sm sm:text-xl my-3 block">
                Degree Duration
              </label>
              <div className="p-1 shadow-all-rounded rounded-2xl">
                <input type="date" className="w-full" />
              </div>

              <label className="font-bold text-sm sm:text-xl my-3 block">
                Batch Year
              </label>
              <div className="p-1 shadow-all-rounded rounded-2xl">
                <input type="date" className="w-full" />
              </div>

              <label className="font-bold text-sm sm:text-xl my-3 block">
                Class
              </label>
              <div className="p-1 shadow-all-rounded rounded-2xl">
                <input type="date" className="w-full" />
              </div>

              <label className="font-bold text-sm sm:text-xl my-3 block">
                Department
              </label>
              <div className="p-1 shadow-all-rounded rounded-2xl">
                <input type="date" className="w-full" />
              </div>

              <label className="font-bold text-sm sm:text-xl my-3 block">
                Qualification Level
              </label>
              <div className="p-1 shadow-all-rounded rounded-2xl">
                <input type="date" className="w-full" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end rounded-b-xl gap-8 px-3 py-5 bg-placeholder/50 sticky bottom-0">
          <div
            onClick={closeModal}
            className="bg-white border flex items-center justify-center text-sm text-black font-semibold rounded-2xl px-6 py-2 shadow-all-rounded cursor-pointer"
          >
            Cancel
          </div>
          <div
            onClick={closeModal}
            className="bg-green flex items-center justify-center text-sm text-white font-semibold rounded-2xl px-8 py-2 shadow-all-rounded cursor-pointer"
          >
            Update
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UserFilterModal;
