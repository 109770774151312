const RoutesDirections = {
  Register: "/register",
  Login: "/",
  ForgotPassword: "/forgot/password",
  Help: "/help",
  Home: "/home",
  Schedule: "/schedule",
  LogReport: "/log-report",
  LogReportPdf: "/logReport/:tab",
  ActiveVehicle: "/active-vehicle",
  LiveLocation: "/active-vehicle/live-location",
  Drivers: "/drivers",
  Vehicles: "/vehicles",
  Routes: "/routes",
  Wallet:'/wallet',
  Profile:"/profile",
  TransportUser:"/transport-user",
  ApprovedUser:"/approved-user",
  DisapprovedUser:"/transport-user/disapproved-user",
  PastUser:"/transport-user/past-user",
  QR:"/generate-qr",
  DeactivateAccount: "/profile/deactivate-account",
};

export default RoutesDirections;
