import Input from "../../input/Input";
import UserImg from "../../../assets/images/svgs/user.svg";
import CardImg from "../../../assets/images/svgs/card.svg";
import PhoneImg from "../../../assets/images/svgs/phone.svg";
import CardPic from "../../../assets/images/card-pic.png";
import MapPic from "../../../assets/images/map-pic.png";

export default function CollegeForm() {
  return (
    <div className="grid grid-cols-12 gap-10 px-6 mb-10">
      <div className="col-span-6 max-[768px]:col-span-12">
        <Input name="Full Name" type="text" placeholder="name" icon={UserImg} />
      </div>
      <div className="col-span-6 max-[768px]:col-span-12">
        <Input name="Class" type="text" placeholder="K-S" />
      </div>
      <div className="col-span-6 max-[768px]:col-span-12">
        <Input
          name="Phone Number"
          type="number"
          placeholder="+01 112 xxx xxx"
          icon={PhoneImg}
        />
      </div>
      <div className="col-span-6 max-[768px]:col-span-12">
        <Input name="Roll Number" type="number" placeholder="xxxx" />{" "}
      </div>
      <div className="col-span-6 max-[768px]:col-span-12">
        <Input
          name="CNIC Number"
          type="number"
          placeholder="CNIC number, e.g : +01 112 xxx xxx"
          icon={CardImg}
        />
      </div>

      <div className="col-span-6 max-[768px]:col-span-12">
        <Input name="Section" type="text" placeholder="xxx-x" />
      </div>
      <div className="col-span-6 max-[768px]:col-span-12">
        <Input name="Home Address" type="text" placeholder="x-x-x-x" />
      </div>
      <div className="col-span-6 max-[768px]:col-span-12">
        <Input name="Institute Name" type="text" placeholder="x-x-x-x" />
      </div>
      <div className="col-span-12">
        <Input name="Pickup Address" type="text" placeholder="x-x-x-x" />
      </div>
      <div className="col-span-6 max-[768px]:col-span-12">
        <Input name="Qualification Level" type="text" placeholder="x-x-x-x" />
      </div>
      <div className="col-span-6 max-[768px]:col-span-12">
        <h1 className="font-bold text-sm sm:text-lg mb-4">Map</h1>

        <img src={MapPic} alt="" className="w-full" />
      </div>
      <div className="col-span-6 max-[768px]:col-span-12">
        <Input name="Degree Duration" type="text" placeholder="x-x-x-x" />
      </div>
      <div className="col-span-6 max-[768px]:col-span-12">
        <Input name="Batch Year" type="text" placeholder="x-x-x-x" />
      </div>

      <div className="col-span-6 max-[768px]:col-span-12">
        <h1 className="font-bold text-sm sm:text-lg mb-4">Student Card</h1>

        <img src={CardPic} alt="" />
      </div>

      <div className="col-span-6 p-8 border border-black rounded-2xl max-[768px]:col-span-12">
        <div className="mb-6">
          <h1 className="text-lg font-bold mb-2">
            Here you can specify a route for the student
          </h1>
          <select className="border shadow-all-rounded px-3 py-2 rounded-xl outline-0 cursor-pointer">
            <option value="route1">Route#1</option>
            <option value="route2">Roue#2</option>
          </select>
        </div>
        <div className="mb-6">
          <h1 className="text-lg font-bold mb-2">Transport Starting Date</h1>
          <input
            type="date"
            className="border shadow-all-rounded px-3 py-2 rounded-xl"
          />
        </div>
        <div>
          <h1 className="text-lg font-bold mb-2">Transport Ending Date</h1>
          <input
            type="date"
            className="border shadow-all-rounded px-3 py-2 rounded-xl"
          />
        </div>
      </div>
    </div>
  );
}
