import { Awaiting_Approvals } from "../../../data/transportUserData";
import EmployeeForm from "../../../components/forms/EmployeeForm";
import UniversityForm from "../../../components/forms/UniversityForm";
import SchoolForm from "../../../components/forms/SchoolForm";
import CollegeForm from "../../../components/forms/CollegeForm";
import CollegeGaurdian from "../../../components/forms/GaurdianForm/CollegeGaurdian";
import SchoolGaurdian from "../../../components/forms/GaurdianForm/SchoolGaurdian";
import UniversityGaurdian from "../../../components/forms/GaurdianForm/UniversityGaurdian";
import { useState } from "react";
import TransportUserTables from "../../tables/TransportUserTables";

const TransportUserTable = ({ setShowPDF }) => {
  const [showFilter, setShowFilter] = useState(false);
  const [showFormMenu, setShowFormMenu] = useState(false);
  const [showSchoolForm, setShowSchoolForm] = useState(false);
  const [showCollegeForm, setShowCollegeForm] = useState(false);
  const [showUniversityForm, setShowUniversityForm] = useState(false);
  const [showEmployeeForm, setShowEmployeeForm] = useState(false);
  const [showCollegeGaurdianForm, setShowCollegeGaurdianForm] = useState(false);
  const [showSchoolGaurdianForm, setShowSchoolGaurdianForm] = useState(false);
  const [showUniversityGaurdianForm, setShowUniversityGaurdianForm] =
    useState(false);

  const columns = [
    "Title",
    "Roll No / Emp ID",
    "Class / Department",
    "Gaurdians",
    "Status",
  ];
  return (
    <div
      className="flex flex-col h-[72vh] max-w-full justify-between  shadow-all-rounded rounded-xl"
      style={{ height: "85%" }}
    >
      <div className="overflow-x-auto">
        <div className="w-full inline-block align-middle">
          <div className="overflow-hidden">
            <TransportUserTables
              columns={columns}
              TableData={Awaiting_Approvals}
            />
          </div>
        </div>
      </div>
      <div className="bg-placeholder/50 p-5 rounded-b-xl flex flex-row space-y-3 md:space-y-0 items-center justify-between">
        <div
          className="bg-light-orange text-white text-lg w-36 text-center py-3 px-4 rounded-3xl shadow-all-rounded flex items-center space-x-1 justify-center cursor-pointer"
          onClick={() => setShowPDF(true)}
        >
          <p>Create PDF</p>
        </div>
      </div>
      <EmployeeForm
        modalOpen={showEmployeeForm}
        setModalOpen={setShowEmployeeForm}
      />
      <UniversityForm
        modalOpen={showUniversityForm}
        setModalOpen={setShowUniversityForm}
      />
      <CollegeForm
        modalOpen={showCollegeForm}
        setModalOpen={setShowCollegeForm}
      />
      <SchoolForm modalOpen={showSchoolForm} setModalOpen={setShowSchoolForm} />
      <CollegeGaurdian
        modalOpen={showCollegeGaurdianForm}
        setModalOpen={setShowCollegeGaurdianForm}
      />
      <SchoolGaurdian
        modalOpen={showSchoolGaurdianForm}
        setModalOpen={setShowSchoolGaurdianForm}
      />
      <UniversityGaurdian
        modalOpen={showUniversityGaurdianForm}
        setModalOpen={setShowUniversityGaurdianForm}
      />
    </div>
  );
};

export default TransportUserTable;
