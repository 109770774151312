import jsPDF from "jspdf";
import "jspdf-autotable";
import pdfImg from "../../src/assets/images/pdfData.png";

export default function PDF({ Title, pdfName, TableContent, columns }) {
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);

    const tableColumns = Object.keys(TableContent[0]);

    const title = Title;
    const headers = [columns];
    const pdfData = TableContent.map((head) => [
      tableColumns.map((element) => [head[element]]),
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: pdfData,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(pdfName);
  };

  return (
    <div className="max-w-6xl mx-auto h-full">
      <div className="flex flex-col sm:flex-row flex-wrap gap-4 mb-5">
        <img src={pdfImg} />
      </div>
      <div className="bg-placeholder/50 p-4  rounded-b-xl flex flex-col sm:flex-row space-y-3 space-x-8 md:space-y-0 items-end justify-end">
        <div className="bg-white text-green text-lg w-36 text-center py-3 px-5 rounded-3xl shadow-all-rounded cursor-pointer">
          Share
        </div>
        <div
          className="bg-green text-white text-lg w-36 text-center py-3 px-5 rounded-3xl shadow-all-rounded cursor-pointer"
          onClick={exportPDF}
        >
          Save PDF
        </div>
      </div>
    </div>
  );
}
