import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    home: true,
    drivers: false,
    vehicles: false,
    routes: false,
    profile: false,
    "code for parents/users": false,
    wallet: false,
    help: false,
    logout: false,
  },
  logPage: "driver",
  ham: false,
};

export const navbarReducer = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    setNavValue: (state, action) => {
      state.value = action.payload;
    },
    setHam: (state, action) => {
      state.ham = action.payload;
    },
    setLogPage: (state, action) => {
      state.logPage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNavValue, setHam, setLogPage, setProfilePicture } =
  navbarReducer.actions;

export const selectNavValue = (state) => state.navbar.value;
export const selectHam = (state) => state.navbar.ham;
export const selectLogPage = (state) => state.navbar.logPage;

export default navbarReducer.reducer;
