export const TripData = [
  {
    route_no: "10-Gujranwala to Lahore",
    vehicle_reg: "MLT-987",
    driver: "Ali",
    date: "21/08/22",
    time: "02:00 PM / 7:15 PM",
  },
  {
    route_no: "12-Multan to Islamabad",
    vehicle_reg: "LHR-123",
    driver: "Azam",
    date: "21/08/22",
    time: "02:00 PM / 7:15 PM",
  },
  {
    route_no: "14-Multan to Gujranwala",
    vehicle_reg: "GWR-345",
    driver: "Atif",
    date: "21/08/22",
    time: "02:00 PM / 7:15 PM",
  },
];