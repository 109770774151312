import { Active_Vehicle } from "../../../data/activeVehicleData";
import ActiveVehicleTabelItem from "./ActiveVehicleTabelItem";

const ActiveVehicleTable = ({ setShowVehicleLocation }) => {
  return (
    <div className="overflow-y-auto h-full">
      <div>
        <div
          className={`flex ${"grid-cols-2"}  bg-placeholder/50 p-3 rounded-t-xl font-bold px-10 md:px-16 lg:px-28 justify-between`}
        >
          <p className="text-2xl font-bold">Vehicles</p>
          <p className="text-2xl font-bold">Location</p>
        </div>
        <div className="overflow-y-auto">
          {Active_Vehicle.map((row, i) => (
            <ActiveVehicleTabelItem
              key={i}
              tab={row}
              setShowVehicleLocation={setShowVehicleLocation}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActiveVehicleTable;
