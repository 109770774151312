import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiTwotoneFilter, AiOutlinePlus } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import Layout from "../../../layout/Layout";
import ApprovedUserTable from "../../../components/home/transport-user/ApprovedUserTable";
import RoutesDirections from "../../../routes/types";
import CustomMenu from "../../../components/shared/CustomMenu";
import EmployeeForm from "../../../components/forms/EmployeeForm";
import UniversityForm from "../../../components/forms/UniversityForm";
import SchoolForm from "../../../components/forms/SchoolForm";
import CollegeForm from "../../../components/forms/CollegeForm";
import CollegeGaurdian from "../../../components/forms/GaurdianForm/CollegeGaurdian";
import SchoolGaurdian from "../../../components/forms/GaurdianForm/SchoolGaurdian";
import UniversityGaurdian from "../../../components/forms/GaurdianForm/UniversityGaurdian";
import { MenuItem } from "../../../data/FormMenuData";
import { Awaiting_Approvals } from "../../../data/transportUserData";
import PDF from "../../../createPDF/PDF";
import UserFilterModal from "../../../components/home/transport-user/UserFilterModal";

const ApprovedUser = () => {
  const columns = [
    "Title",
    "Roll No / Emp ID",
    "Class / Department",
    "Gaurdians",
    "Status",
  ];
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [selectedApprovals, setSelectedApprovals] = useState("/approved-user");
  const [showFormMenu, setShowFormMenu] = useState(false);
  const [showSchoolForm, setShowSchoolForm] = useState(false);
  const [showCollegeForm, setShowCollegeForm] = useState(false);
  const [showUniversityForm, setShowUniversityForm] = useState(false);
  const [showEmployeeForm, setShowEmployeeForm] = useState(false);
  const [showCollegeGaurdianForm, setShowCollegeGaurdianForm] = useState(false);
  const [showSchoolGaurdianForm, setShowSchoolGaurdianForm] = useState(false);
  const [showUniversityGaurdianForm, setShowUniversityGaurdianForm] =
    useState(false);

  useEffect(() => {
    navigate(selectedApprovals);
    // eslint-disable-next-line
  }, [selectedApprovals]);

  const showSelectedForm = (selectedFormValue) => {
    setShowFormMenu(!showFormMenu);

    switch (selectedFormValue) {
      case "school":
        setShowSchoolForm(true);
        break;
      case "college":
        setShowCollegeForm(true);
        break;
      case "university":
        setShowUniversityForm(true);
        break;
      case "employee":
        setShowEmployeeForm(true);
        break;
      case "gaurdian-school":
        setShowSchoolGaurdianForm(true);
        break;
      case "gaurdian-college":
        setShowCollegeGaurdianForm(true);
        break;
      case "gaurdian-university":
        setShowUniversityGaurdianForm(true);
        break;
      default:
        break;
    }
  };

  return (
    <Layout>
      {showPDF ? (
        <PDF
          Title="Approved Users"
          pdfName="ApprovedUser"
          TableContent={Awaiting_Approvals}
          columns={columns}
        />
      ) : (
        <div className="max-w-6xl mx-auto h-full">
          <div className="flex flex-col sm:flex-row flex-wrap gap-4 mb-5">
            <div className="flex flex-grow items-center border rounded-xl border-black">
              <BsSearch size={25} className="ml-3 mr-1" />
              <input
                className="px-3 py-2 rounded-xl text-xl outline-0 w-full"
                type="search"
                placeholder="Search"
              />
              <AiTwotoneFilter
                size={25}
                onClick={() => setShowFilter(true)}
                className="mr-3 ml-1 cursor-pointer"
              />
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="mr-4">
                <div
                  onClick={() => setShowFormMenu(!showFormMenu)}
                  className="bg-green flex items-center justify-center text-xl text-white font-semibold rounded-2xl px-3 py-2 shadow-all-rounded cursor-pointer"
                >
                  <AiOutlinePlus className="font-semibold mr-3" />
                  <p className=" whitespace-nowrap">Add</p>
                </div>
                {showFormMenu && (
                  <div className="absolute z-40 md:right-56 right-40 mt-2">
                    <CustomMenu
                      MenuItem={MenuItem}
                      showSelectedForm={showSelectedForm}
                    />
                  </div>
                )}
              </div>

              <select
                onChange={(e) => setSelectedApprovals(e.target.value)}
                value={selectedApprovals}
                className="border shadow-all-rounded px-3 py-2 rounded-xl outline-0 cursor-pointer font-bold text-[#606060] bg-light-yellow"
              >
                <option
                  value={RoutesDirections.ApprovedUser}
                  className="font-bold text-black"
                >
                  Approved Users
                </option>
                <option
                  value={RoutesDirections.TransportUser}
                  className="font-bold text-black"
                >
                  Awaiting Approvals
                </option>
                <option
                  value={RoutesDirections.DisapprovedUser}
                  className="font-bold text-black"
                >
                  Disapproved Users
                </option>
                <option
                  value={RoutesDirections.PastUser}
                  className="font-bold text-black"
                >
                  Past Users
                </option>
              </select>
            </div>
          </div>
          <ApprovedUserTable
            setShowSchoolForm={setShowSchoolForm}
            setShowPDF={setShowPDF}
          />
          <UserFilterModal modalIsOpen={showFilter} setIsOpen={setShowFilter} />
          <EmployeeForm
            modalOpen={showEmployeeForm}
            setModalOpen={setShowEmployeeForm}
          />
          <UniversityForm
            modalOpen={showUniversityForm}
            setModalOpen={setShowUniversityForm}
          />
          <CollegeForm
            modalOpen={showCollegeForm}
            setModalOpen={setShowCollegeForm}
          />
          <SchoolForm
            modalOpen={showSchoolForm}
            setModalOpen={setShowSchoolForm}
          />
          <CollegeGaurdian
            modalOpen={showCollegeGaurdianForm}
            setModalOpen={setShowCollegeGaurdianForm}
          />
          <SchoolGaurdian
            modalOpen={showSchoolGaurdianForm}
            setModalOpen={setShowSchoolGaurdianForm}
          />
          <UniversityGaurdian
            modalOpen={showUniversityGaurdianForm}
            setModalOpen={setShowUniversityGaurdianForm}
          />
        </div>
      )}
    </Layout>
  );
};

export default ApprovedUser;
