export const Active_Vehicle = [
  {
    id: 1,
    vehicle: "LHE-2345",
  },

  {
    id: 2,
    vehicle: "LHE-2345",
  },

  {
    id: 3,
    vehicle: "LHE-2345",
  },
  {
    id: 4,
    vehicle: "LHE-2345",
  },
  {
    id: 5,
    vehicle: "LHE-2345",
  },
  {
    id: 6,
    vehicle: "LHE-2345",
  },
  {
    id: 7,
    vehicle: "LHE-2345",
  },
  {
    id: 8,
    vehicle: "LHE-2345",
  },
  {
    id: 9,
    vehicle: "LHE-2345",
  },
  {
    id: 10,
    vehicle: "LHE-2345",
  },
  {
    id: 11,
    vehicle: "LHE-2345",
  },
  {
    id: 12,
    vehicle: "LHE-2345",
  },
  {
    id: 13,
    vehicle: "LHE-2345",
  },
  {
    id: 14,
    vehicle: "LHE-2345",
  },
  {
    id: 15,
    vehicle: "LHE-2345",
  },
  {
    id: 16,
    vehicle: "LHE-2345",
  }
]
