import Input from "../../input/Input";
import UserImg from "../../../assets/images/svgs/user.svg";
import CardImg from "../../../assets/images/svgs/card.svg";
import PhoneImg from "../../../assets/images/svgs/phone.svg";
import CardPic from "../../../assets/images/card-pic.png";
import CameraBtn from "../../../assets/images/svgs/camera.svg";

export default function GaurdianForm() {
  return (
    <div>
      <div className="flex flex-row items-center justify-between px-6 max-[768px]:flex-col max-[768px]:gap-2">
        <h1 className="font-bold text-lg text-slate-600">
          Gaurdian Information
        </h1>
        <div className="p-3 bg-white shadow-all-rounded h-20 w-20 rounded-full cursor-pointer flex items-center justify-center">
          <img src={CameraBtn} alt="camera" className="h-4" />
          <input type="file" className="hidden" />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-10 px-6 mb-10">
        <div className="col-span-6 max-[768px]:col-span-12">
          <Input
            name="Full Name"
            type="text"
            placeholder="name"
            icon={UserImg}
          />
        </div>
        <div className="col-span-6 max-[768px]:col-span-12">
          <Input name="Relationship" type="text" placeholder="xxxx" />
        </div>
        <div className="col-span-6 max-[768px]:col-span-12">
          <Input
            name="Phone Number"
            type="number"
            placeholder="+01 112 xxx xxx"
            icon={PhoneImg}
          />
        </div>

        <div className="col-span-6 max-[768px]:col-span-12">
          <Input name="Home Address" type="text" placeholder="x-x-x-x" />
        </div>

        <div className="col-span-6 max-[768px]:col-span-12">
          <Input
            name="CNIC Number"
            type="number"
            placeholder="CNIC number, e.g : +01 112 xxx xxx"
            icon={CardImg}
          />
        </div>

        <div className="col-span-6 max-[768px]:col-span-12">
          <h1 className="font-bold text-sm sm:text-lg mb-4">Card Image</h1>

          <img src={CardPic} alt="" />
        </div>
      </div>
    </div>
  );
}
