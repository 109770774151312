import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiTwotoneFilter } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import Layout from "../../../layout/Layout";
import TransportUserTable from "../../../components/home/transport-user/TransportUserTable";
import RoutesDirections from "../../../routes/types";
import { Awaiting_Approvals } from "../../../data/transportUserData";
import PDF from "../../../createPDF/PDF";
import UserFilterModal from "../../../components/home/transport-user/UserFilterModal";

export const TransportUser = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [selectedApprovals, setSelectedApprovals] = useState("/transport-user");
  const navigate = useNavigate();

  const columns = [
    "Title",
    "Roll No / Emp ID",
    "Class / Department",
    "Gaurdians",
    "Status",
  ];

  useEffect(() => {
    navigate(selectedApprovals);
    // eslint-disable-next-line
  }, [selectedApprovals]);
  return (
    <Layout>
      {showPDF ? (
        <PDF
          Title="Awaiting Users"
          pdfName="AwaitingUser"
          TableContent={Awaiting_Approvals}
          columns={columns}
        />
      ) : (
        <div className="max-w-6xl mx-auto h-full">
          <div className="flex flex-col sm:flex-row flex-wrap gap-4 mb-5">
            <div className="flex flex-grow items-center border rounded-xl border-black">
              <BsSearch size={25} className="ml-3 mr-1" />
              <input
                className="px-3 py-2 rounded-xl text-xl outline-0 w-full"
                type="search"
                placeholder="Search"
              />
              <AiTwotoneFilter
                size={25}
                onClick={() => setShowFilter(true)}
                className="mr-3 ml-1 cursor-pointer"
              />
            </div>

            <select
              onChange={(e) => setSelectedApprovals(e.target.value)}
              value={selectedApprovals}
              className="border shadow-all-rounded px-3 py-2 rounded-xl outline-0 cursor-pointer font-bold text-[#606060] bg-light-yellow"
            >
              <option
                value={RoutesDirections.ApprovedUser}
                className="font-bold text-black"
              >
                Approved Users
              </option>
              <option
                value={RoutesDirections.TransportUser}
                className="font-bold text-black"
              >
                Awaiting Approvals
              </option>
              <option
                value={RoutesDirections.DisapprovedUser}
                className="font-bold text-black"
              >
                Disapproved Users
              </option>
              <option
                value={RoutesDirections.PastUser}
                className="font-bold text-black"
              >
                Past Users
              </option>
            </select>
          </div>
          <TransportUserTable setShowPDF={setShowPDF} />
          <UserFilterModal modalIsOpen={showFilter} setIsOpen={setShowFilter} />
        </div>
      )}
    </Layout>
  );
};
