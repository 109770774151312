import Layout from "../../layout/Layout";
import accountDeactivate from "../../assets/images/accountDeactivate.png";
import { useState } from "react";
import AccountDeactivateModal from "./AccountDeactivateModal";
import AccountDeactivateCodeModal from "./AccountDeactivateCodeModal";
import AccountDeactivateConfirmModal from "./AccountDeactivateConfirmModal";

export default function AccountDeactivate() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [codeModalIsOpen, setCodeModalIsOpen] = useState(false);
  const [confirmModalIsOpen, setconfirmModalIsOpen] = useState(false);
  const handleModal = (showModal) => {
    setIsOpen(showModal);
  };
  const handleCodeModal = (showCodeModal) => {
    setCodeModalIsOpen(showCodeModal);
  };
  const handleConfirmModal = (showCodeConfirmModal) => {
    setconfirmModalIsOpen(showCodeConfirmModal);
  };
  
  return (
    <Layout>
      <div className="flex items-center w-full relative">
        <div className="space-y-10 w-full z-50">
          <div className=" ml-10  justify-center text-center">
            <span className="font-bold text-5xl ">
              Deactivate your <br></br> account
            </span>
          </div>
          <div className="font-semibold text-xl  text-start w-96 ml-8">
            Please note that your organization's transportation service facility
            will be suspended(at all levels) by deactivating your account.
          </div>
          <div className="mt-7 ml-28">
            <button
              type="button"
              className={`bg-red font-bold text-white text-xl rounded-3xl px-10 py-2.5`}
              onClick={() => setIsOpen(true)}
            >
              Deactivate your account
            </button>
          </div>
        </div>
        <div className="absolute top-20 left-60 z-0">
          <img src={accountDeactivate} alt="" />
        </div>
      </div>
      <AccountDeactivateModal
        modalIsOpen={modalIsOpen}
        handleModal={handleModal}
        handleCodeModal={handleCodeModal}
      />
      <AccountDeactivateCodeModal
        handleCodeModal={handleCodeModal}
        codeModalIsOpen={codeModalIsOpen}
        handleConfirmModal={handleConfirmModal}
      />
      <AccountDeactivateConfirmModal
        handleConfirmModal={handleConfirmModal}
        confirmModalIsOpen={confirmModalIsOpen}
      />
    </Layout>
  );
}
