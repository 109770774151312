import Request from "../request";

const getAll = async () => {
  try {
    const response = await Request.get("manager/main-screen-wrapper/");
    return { exception: false, data: response.data.data };
  } catch (error) {
    return { exception: true, errors: error.response.data.message };
  }
};

export default { getAll };
