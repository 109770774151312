import ReportPdf from "../../../components/home/log-report/log-report-pdf/LogReportPdf";
import Layout from "../../../layout/Layout";

const LogReportPdf = () => {
  return (
    <Layout>
      <div className="shadow-all-rounded rounded-xl">
        <ReportPdf />
      </div>
    </Layout>
  );
};

export default LogReportPdf;
