const LogReportTableItem = ({ tab }) => {
  return (
    <div
      className={`flex ${"grid-cols-5"} hover:bg-light-yellow gap-8 px-10 md:px-16 lg:px-28 py-3`}
    >
      <input
        type="checkbox"
        className="w-[1.025rem] cursor-pointer"
      />
      <p className="font-semibold text-lg">{tab.name}</p>
    </div>
  );
};

export default LogReportTableItem;
