import { BsPlus } from "react-icons/bs";
import ExpenseCard from "../shared/CustomCard";
import Statics from "./wallet-components/Statics";
import IconButton from "./wallet-components/IconButton";
import BalanceCard from "./wallet-components/BalanceCard";
import Notification from "./wallet-components/Notification";
import ArrowUp from "../../assets/icons/arrow-up.svg";
import ArrowDown from "../../assets/icons/arrow-down.svg";
import ExportIcon from "../../assets/icons/export.svg";
import ImportIcon from "../../assets/icons/import.svg";

const Wallet = () => {
  return (
    <div className="shadow-all-rounded rounded-xl p-4 h-full overflow-y-auto">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-8 max-[1160px]:col-span-7 max-[900px]:col-span-6 max-[830px]:col-span-12 max-[768px]:col-span-6 max-[550px]:col-span-12">
          <div className="grid grid-cols-2 items-center">
            <h2 className="font-bold text-2xl mt-16 ml-4">Your card</h2>
            <div className="flex flex-row border mt-16 items-center justify-center py-3 gap-2 rounded-3xl hover:shadow-xl cursor-pointer w-32">
              <span className="text-sm font-bold">Add Card</span>
              <BsPlus size={25} />
            </div>
          </div>
          <div className="min-[1041px]:grid min-[1041px]:grid-cols-2 my-6 gap-4 max-[1041px]:flex max-[1040px]:flex-col">
            <ExpenseCard
              title="Earning"
              amount="21,500.00"
              icon={ArrowUp}
              percentage="12"
            />
            <ExpenseCard
              title="Spending"
              amount="21,500.00"
              icon={ArrowDown}
              percentage="18"
            />
          </div>
          <Statics />
        </div>
        <div className="col-span-4 max-[1160px]:col-span-5 max-[900px]:col-span-6 max-[830px]:col-span-12 max-[768px]:col-span-6 max-[550px]:col-span-12">
          <BalanceCard />
          <div className="min-[1041px]:grid min-[1041px]:grid-cols-2 my-6 px-5 gap-4 max-[1160px]:gap-3 max-[1160px]:px-3 max-[1041px]:flex max-[1040px]:flex-col">
            <IconButton icon={ExportIcon} text="Send" />
            <IconButton icon={ImportIcon} text="Recieve" />
          </div>
          <Notification />
        </div>
      </div>
    </div>
  );
};

export default Wallet;
