import Request from "./request";

const fetchVehicles = async () => {
  try {
    const response = await Request.get("manager/web/vehicle");
    console.log(response.data.data);
    return { exception: false, data: response.data.data };
  } catch (error) {
    return { exception: true, errors: error.response.data.message };
  }
};

const createVehicle = async (data) => {
  try {
    const response = await Request.post("manager/web/vehicle", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return { exception: false, data: response.data };
  } catch (error) {
    return { exception: true, errors: error.response };
  }
};

export default { fetchVehicles, createVehicle };
