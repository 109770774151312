import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginImage from "../../assets/images/svgs/login.svg";
import Button from "../../components/button/Button";
import Header from "../../components/header/Header";
import RoutesDirections from "../../routes/types";
import managerService from "../../services/manager";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import "yup-phone-lite";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [btn, setBtn] = useState(false);
  const initialValues = {
    phone: "",
    password: "",
  };

  const validationSchema = yup.object({
    phone: yup
      .string()
      .phone("PK", "Please enter a valid phone number")
      .required("A phone number is required"),
    password: yup
      .string()
      .min(8, "Password must be between 8 and 25 characters")
      .max(25, "Password must be between 8 and 25 characters")
      .required("Required"),
  });

  const navigate = useNavigate();
  const handleClick = async (values, { resetForm }) => {
    setBtn(true);
    const response = await managerService.login({
      phone: values.phone,
      password: values.password,
    });
    if (!response.exception) {
      toast.success("Success", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      navigate(RoutesDirections.Home);
    } else {
      toast.error(response.errors, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    setBtn(false);
    resetForm();
  };

  return (
    <div className="min-h-screen">
      <Header />
      <div className="flex flex-col-reverse grow-0 lg:flex-row justify-center mt-20  px-8 h-[75vh]">
        <div className="flex lg:ml-auto">
          <div className="flex flex-col flex-grow mx-auto">
            <div className=" mb-[2.625rem] text-center">
              <p className="font-bold text-lg sm:text-[2rem]">Welcome Back</p>
              <p className="text-placeholder text-[0.9375rem] mt-2">
                Welcome back! please enter your details.
              </p>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleClick}
            >
              <Form className="flex flex-col">
                <label
                  htmlFor="phone"
                  className="font-bold text-sm sm:text-lg mb-4"
                >
                  Phone Number
                </label>
                <Field
                  type="text"
                  id="phone"
                  name="phone"
                  className="flex items-center border border-placeholder mb-4 p-4 rounded-[1.25rem] text-sm sm:text-[0.9375rem] flex-grow"
                />
                <small className="text-red">
                  <ErrorMessage name="phone" />
                </small>

                <label
                  htmlFor="password"
                  className="font-bold text-sm sm:text-lg mb-4"
                >
                  Password
                </label>
                <Field
                  type="password"
                  id="password"
                  name="password"
                  className="flex items-center border border-placeholder mb-4 p-4 rounded-[1.25rem] text-sm sm:text-[0.9375rem] flex-grow"
                />
                <ErrorMessage name="password" />
                <div className="flex justify-between text-xs">
                  <div className="flex items-center">
                    <input type="checkbox" />
                    <p className=" ml-2">Stay logged in?</p>
                  </div>
                  <p className="text-green cursor-pointer">
                    <Link to={RoutesDirections.ForgotPassword}>
                      Forgot Password?
                    </Link>
                  </p>
                </div>
                <div className="mt-4 flex-grow">
                  <Button text="Login" type="submit" isDisabled={btn} />
                  <p className="text-xs text-center mt-2">
                    Don’t have an account yet?
                    <span className="text-blue font-bold">
                      <Link to={RoutesDirections.Register}>Register here</Link>
                    </span>
                  </p>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
        <div className="flex max-w-xl mx-auto mb-10 lg:mb-0 lg:mr-0">
          <img
            src={LoginImage}
            alt="login"
            className="box-shadow: 0 25px 50px -12px rgba(0,0,0,0.25);"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
