import Modal from "react-modal";
// import Input from "../../input/Input";
import useWindowSize from "../../../utils/useWindowSize";

const ScheduleModal = ({ modalIsOpen, setIsOpen, onEdit }) => {
  const { width } = useWindowSize();

  const customStyles = {
    content: {
      top: "95px",
      left: "auto",
      right: "10px",
      bottom: "auto",
      width:
        width < 768
          ? "23rem"
          : width > 768 && width < 1024
          ? "35rem"
          : width >= 1024 && "45rem",
      boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.25)",
      borderRadius: "20px",
      padding: "0px",
    },
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="p-5 h-[76vh] overflow-y-auto">
          <div className="flex items-center mb-8 top-0">
            <div className="font-bold text-xl md:text-3xl flex-grow text-center">
              Create Schedule
              {/* {onEdit ? "View/Edit Driver" : "Create Schedule"} */}
            </div>
          </div>
          <div className="mt-20">
            <div className="max-w-lg mx-auto ">
              <label className="font-bold text-sm sm:text-xl mb-4">
                Route No
              </label>
              <select className="py-2 px-4 w-auto sm:w-full text-sm sm:text-lg flex items-center border border-placeholder rounded-[1.25rem] mb-4 mt-4">
                {data.map((content, key) => (
                  <option className="rounded-[1.25rem] text-sm sm:text-xl outline-none flex-grow">
                    {content.routes}
                  </option>
                ))}
              </select>

              <label className="font-bold text-sm sm:text-xl mb-4">
                Vehicle Reg
              </label>
              <select className="py-2 px-4 w-auto sm:w-full text-sm sm:text-lg flex items-center border border-placeholder rounded-[1.25rem] mb-4 mt-4">
                {data.map((content, key) => (
                  <option className="rounded-[1.25rem] text-sm sm:text-xl outline-none flex-grow">
                    {content.regNo}
                  </option>
                ))}
              </select>

              <label className="font-bold text-sm sm:text-xl mb-4">
                Driver
              </label>
              <select className="py-2 px-4 w-auto sm:w-full text-sm sm:text-lg flex items-center border border-placeholder rounded-[1.25rem] mb-4 mt-4">
                {data.map((content, key) => (
                  <option className="rounded-[1.25rem] text-sm sm:text-xl outline-none flex-grow">
                    {content.name}
                  </option>
                ))}
              </select>
              <label className="font-bold text-sm sm:text-xl mb-4 block">
                Time
              </label>
              <input
                type="time"
                id="appt"
                name="appt"
                className="py-2 px-4 w-auto sm:w-full text-sm sm:text-lg flex items-center border border-placeholder rounded-[1.25rem] mb-4 mt-4"
              />
            </div>
          </div>
        </div>
        {onEdit ? (
          <div className="flex justify-end rounded-b-xl gap-8 px-3 py-5 bg-placeholder/50">
            <div
              onClick={closeModal}
              className="bg-white flex items-center justify-center text-sm sm:text-lg text-black font-semibold rounded-3xl px-4 py-2 shadow-all-rounded cursor-pointer"
            >
              <p className=" whitespace-nowrap">Cancel</p>
            </div>
            <div
              className="bg-green flex items-center justify-center text-sm sm:text-lg text-white font-semibold rounded-3xl px-6 py-2 shadow-all-rounded cursor-pointer"
              onClick={closeModal}
            >
              <p className=" whitespace-nowrap">Add</p>
            </div>
          </div>
        ) : (
          <div className="flex justify-end rounded-b-xl gap-8 px-3 py-5 bg-placeholder/50">
            <div
              onClick={closeModal}
              className="bg-white border flex items-center justify-center text-sm text-black font-semibold rounded-2xl px-6 py-2 shadow-all-rounded cursor-pointer"
            >
              <p className=" whitespace-nowrap">Cancel</p>
            </div>

            <div
              onClick={closeModal}
              className="bg-green flex items-center justify-center text-sm text-white font-semibold rounded-2xl px-8 py-2 shadow-all-rounded cursor-pointer"
            >
              <p className=" whitespace-nowrap">Update</p>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

const data = [
  {
    name: "Ghafoor",
    regNo: "LHE-2345",
    routes: "10 GRW To FSD",
  },
  {
    name: "Ghafoor",
    regNo: "LHE-2345",
    routes: "10 GRW To FSD",
  },
  {
    name: "Ghafoor",
    regNo: "LHE-2345",
    routes: "10 GRW To FSD",
  },
  {
    name: "Ghafoor",
    regNo: "LHE-2345",
    routes: "10 GRW To FSD",
  },
];

export default ScheduleModal;
