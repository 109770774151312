import React from "react";
import Layout from "../../layout/Layout";
import ScheduleImg from "../../assets/images/svgs/schedule.svg";
import LogReportImg from "../../assets/images/svgs/log-report.svg";
import ActiveVehiclesImg from "../../assets/images/svgs/active_vehicle.svg";
import TransportUsersImg from "../../assets/images/svgs/transport-users.svg";
import HomeItem from "../../components/home/HomeItem";
import RoutesDirections from "../../routes/types";

const Home = () => {
  return (
    <Layout>
      <div className="flex max-w-xl mx-10 md:mx-auto flex-col items-center justify-center space-y-6 mt-20">
        <HomeItem
          text="Schedule"
          icon={ScheduleImg}
          link={RoutesDirections.Schedule}
        />
        <HomeItem
          text="Log Report"
          icon={LogReportImg}
          link={RoutesDirections.LogReport}
        />
        <HomeItem
          text="Active Vehicles"
          icon={ActiveVehiclesImg}
          link={RoutesDirections.ActiveVehicle}
        />
        <HomeItem
          text="Transport Users"
          icon={TransportUsersImg}
          link={RoutesDirections.ApprovedUser}
        />
      </div>
    </Layout>
  );
};

export default Home;
