import React from "react";
import { useSelector } from "react-redux";
import { selectHam } from "../redux/features/navbarReducer";
import Header from "../components/header/Header";
import SideBar from "../components/sidebar/SideBar";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const value = useSelector(selectHam);
  const currentLocation = useLocation();
  return (
    <div className="min-h-screen flex flex-col">
      <Header auth />
      <div
        className={`flex h-[75vh] flex-grow ${value && "overflow-y-auto"} ${
          currentLocation.pathname === "/home" ? "bg-gray-300" : ""
        } `}
      >
        {value && <SideBar />}
        <div
          className={`flex-grow ${
            currentLocation.pathname === "/home"
              ? "ml-20 mr-64  bg-white border border-gray-200 rounded-lg shadow-xl"
              : "my-6 ml-6 mr-5 md:mr-36"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
