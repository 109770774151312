import Request from "./request";

const getRoutes = async () => {
  try {
    const response = await Request.get("manager/web/route");
    return { exception: false, data: response.data.data };
  } catch (error) {
    return { exception: true, errors: error.response.data.message };
  }
};

export default { getRoutes };
