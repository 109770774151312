import React, { useRef, useState } from "react";
import RegisterImage from "../../assets/images/svgs/register.svg";
import CameraBtn from "../../assets/images/svgs/camera.svg";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
import Header from "../../components/header/Header";
import RoutesDirections from "../../routes/types";
import managerService from "../../services/manager";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import "yup-phone-lite";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Registration = () => {
  const navigate = useNavigate();
  const [btn, setBtn] = useState(false);

  const initialValues = {
    name: "",
    phone: "",
    otp: "",
    password: "",
    password_confirmation: "",
  };

  const validationSchema = yup.object({
    name: yup.string().required("Required"),
    phone: yup
      .string()
      .phone("PK", "Please enter a valid phone number")
      .required("A phone number is required"),
    otp: yup
      .string()
      .min(4, "OTP must be of lenght 4")
      .max(4, "OTP must be of lenght 4")
      .required("Required"),
    password: yup
      .string()
      .min(8, "Password must be between 8 and 25 characters")
      .max(25, "Password must be between 8 and 25 characters")
      .required("Required"),
    password_confirmation: yup
      .string()
      .min(8)
      .max(25)
      .oneOf([yup.ref("password")], "Password doesn't match")
      .required("Required"),
  });

  const handleClick = async (values, { resetForm }) => {
    setBtn(true);
    const response = await managerService.register({
      name: values.name,
      phone: values.phone,
      otp: values.otp,
      password: values.password,
      password_confirmation: values.password_confirmation,
    });
    if (!response.exception) {
      toast.success("Success", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      navigate(RoutesDirections.Login);
    } else {
      toast.error(response.errors, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    setBtn(false);
    resetForm();
  };
  const hiddenFileInput = useRef();

  const handleClick2 = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    // const fileUploaded = event.target.files[0];
    // props.handleFile(fileUploaded);
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col-reverse lg:flex-row justify-center mt-20 md:mt-10 mb-16 px-8">
        <div className="flex lg:ml-auto">
          <div className="flex flex-col flex-grow">
            <div className=" mb-2 text-center">
              <p className="font-bold text-lg sm:text-[2rem]">
                Create Your Account
              </p>
              <p className="text-placeholder text-[0.9375rem] mt-2">
                Enter the fields below to get started
              </p>
            </div>
            <div className="flex md:hidden items-center flex-col mb-8">
              <div
                className="py-8 px-[30px] bg-white drop-shadow-lg h-fit w-fit rounded-full cursor-pointer"
                onClick={handleClick2}
              >
                <img src={CameraBtn} alt="camera" />
                <img src={CameraBtn} alt="camera" />
                <input
                  type="file"
                  className="hidden"
                  onChange={handleChange}
                  ref={hiddenFileInput}
                />
              </div>
              <p className="font-bold text-sm text-placeholder mt-3">
                Attach your Picture
              </p>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleClick}
            >
              <Form className="flex flex-col">
                <label
                  htmlFor="name"
                  className="font-bold text-sm sm:text-lg mb-2"
                >
                  Full Name
                </label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your Name, e.g : John Doe"
                  className="flex items-center border border-placeholder mb-2 p-3 rounded-[1.25rem] text-sm sm:text-[0.9375rem] flex-grow"
                />
                <ErrorMessage name="name" />

                <label
                  htmlFor="phone"
                  className="font-bold text-sm sm:text-lg mb-2"
                >
                  Phone Number
                </label>
                <Field
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Your phone number, e.g : +01 112 xxx xxx"
                  className="flex items-center border border-placeholder mb-2 p-3 rounded-[1.25rem] text-sm sm:text-[0.9375rem] flex-grow"
                />
                <ErrorMessage name="phone" />

                <label
                  htmlFor="otp"
                  className="font-bold text-sm sm:text-lg mb-2"
                >
                  One Time Password
                </label>
                <Field
                  type="text"
                  id="otp"
                  name="otp"
                  placeholder="Enter Verification Code"
                  className="flex items-center border border-placeholder mb-2 p-3 rounded-[1.25rem] text-sm sm:text-[0.9375rem] flex-grow"
                />
                <ErrorMessage name="otp" />

                <label
                  htmlFor="password"
                  className="font-bold text-sm sm:text-lg mb-2"
                >
                  Create new Password
                </label>
                <Field
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Your password, at least 8 character."
                  className="flex items-center border border-placeholder mb-2 p-3 rounded-[1.25rem] text-sm sm:text-[0.9375rem] flex-grow"
                />
                <ErrorMessage name="password" />

                <label
                  htmlFor="password_confirmation"
                  className="font-bold text-sm sm:text-lg mb-2"
                >
                  Confirm Password
                </label>
                <Field
                  type="password"
                  id="password_confirmation"
                  name="password_confirmation"
                  placeholder="Re-type your password"
                  className="flex items-center border border-placeholder mb-2 p-3 rounded-[1.25rem] text-sm sm:text-[0.9375rem] flex-grow"
                />
                <ErrorMessage name="password_confirmation" />

                <div className="mt-4 flex-grow">
                  <Button text="Register" type="submit" isDisabled={btn} />
                  <ToastContainer limit={1} />
                  <p className="text-xs text-center mt-2">
                    Already have an account?{" "}
                    <span className="text-blue font-bold">
                      <Link to={RoutesDirections.Login}>Login here</Link>
                    </span>
                  </p>
                </div>
              </Form>
            </Formik>
          </div>
          <div className="hidden md:flex items-center flex-col ml-10 mt-14 z-0">
            <div
              className="py-8 px-[30px] bg-white drop-shadow-lg h-fit w-fit rounded-full cursor-pointer"
              onClick={handleClick}
            >
              <img src={CameraBtn} alt="camera" />
              <input
                type="file"
                className="hidden"
                onChange={handleChange}
                ref={hiddenFileInput}
              />
            </div>
            <p className="font-bold text-sm text-placeholder mt-3">
              Attach your Picture
            </p>
          </div>
        </div>
        <div className="flex max-w-2xl mx-auto mb-10 lg:mb-0 lg:m-0">
          <img src={RegisterImage} alt="register" />
        </div>
      </div>
    </div>
  );
};

export default Registration;
