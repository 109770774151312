import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";

export default function HomeTables({ Content, columns, isCheckbox, create }) {
  const tableColumn = ["time", "route_no", "vehicle_reg", "driver"];
  return (
    <div className="overflow-y-auto">
      <table className="table-fixed w-xl sm:table-auto sm:w-full font-semibold">
        <thead className=" bg-placeholder/50">
          <tr className="text-center text-base sm:text-lg p-3 rounded-t-xl font-semibold justify-start space-x-2 px-0.5 sm:px-10 md:px-16 lg:px-28">
            {isCheckbox && (
              <td>
                <input
                  type="checkbox"
                  className="w-[1.025rem] cursor-pointer"
                />
              </td>
            )}
            {columns.map((names, key) => (
              <td key={key} className="py-5 capitalize">
                {names}
              </td>
            ))}
            {create === "true" && <td className="text-blue">Edit </td>}
            {create === "true" && <td className="text-red">Delete</td>}
          </tr>
        </thead>
        {Content.map((row, i) => (
          <tbody className="text-base sm:text-lg text-center">
            <tr key={i}>
              {isCheckbox && (
                <td className="py-3 px-1">
                  <input
                    type="checkbox"
                    className="w-[1.025rem] cursor-pointer"
                    name="checkbox_child"
                  />
                </td>
              )}
              {tableColumn.map((column, key) => (
                <td key={key} className="py-3 px-1">
                  {row[column]}
                </td>
              ))}
              {create === "true" && (
                <td className="mx-auto">
                  <MdModeEditOutline className="text-blue text-xl cursor-pointer mx-auto" />
                </td>
              )}
              {create === "true" && (
                <td>
                  <RiDeleteBin6Line className="text-red text-xl cursor-pointer mx-auto" />
                </td>
              )}
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
}
