export default function CustomMenu({ MenuItem, showSelectedForm }) {
  return (
    <div
      id="dropdown"
      className=" rounded-2xl z-20 list-none text-black bg-white divide-y divide-gray-100 shadow w-auto max-h-80 overflow-y-auto outline-black outline outline-1"
    >
      <ul className="py-2" aria-labelledby="dropdownButton">
        {MenuItem?.map((item, index) => (
          <li key={index}>
            <div
              className="block px-4 py-3 text-base font-semibold text-black hover:bg-light-yellow cursor-pointer"
              onClick={() => showSelectedForm(item?.value)}
            >
              {item?.name}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
