import React from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import FilterItem from "./FilterItem";
import Button from "../../button/Button";

const ScheduleFilterModal = ({ modalIsOpen, setIsOpen }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "23rem",
      boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.25)",
      borderRadius: "20px",
      overflow: "hidden",
      padding: "0px",
    },
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="pt-3">
          <div className="flex items-center mb-8">
            <div className="flex-grow text-base text-center">
              Select one of the following filter
            </div>
            <div onClick={closeModal} className="cursor-pointer">
              <IoClose className="font-bold text-3xl mr-3" />
            </div>
          </div>
          <div className="mx-8 space-y-4 mb-4">
            <FilterItem text="Route No" />
            <FilterItem text="Vehicle Registration" />
            <FilterItem text="Driver" />
            <FilterItem text="Time" />
            <FilterItem text="Time (Ascending)" />
            <FilterItem text="Time (Descending)" />
          </div>
          <div className="bg-placeholder/50 p-5 rounded-b-xl flex items-center justify-end">
            <button
              className="bg-light-orange text-white text-center py-2 px-4 rounded-3xl shadow-all-rounded flex items-center space-x-1 justify-center"
              onClick={closeModal}
            >
              Search
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ScheduleFilterModal;
