import useWindowSize from "../../../utils/useWindowSize";
import { IoClose } from "react-icons/io5";
import Modal from "react-modal";
import MapPic from "../../../assets/images/map-pic.png";

const ActiveVehicleLocationModal = ({
  showVehicleLocation,
  setShowVehicleLocation,
}) => {
  const { width } = useWindowSize();

  const customStyles = {
    content: {
      top: "95px",
      left: "auto",
      right: "10px",
      bottom: "auto",
      width:
        width < 768
          ? "23rem"
          : width > 768 && width < 1024
          ? "35rem"
          : width >= 1024 && "45rem",
      boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.25)",
      borderRadius: "20px",
      overflow: "scroll",
      padding: "0px",
    },
  };

  const closeModal = (e) => {
    e.preventDefault();
    setShowVehicleLocation(false);
  };

  return (
    <div>
      <Modal
        isOpen={showVehicleLocation}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="p-5 h-full overflow-y-scroll scrollbar-hide">
          <div className="flex items-center mb-8 sticky top-0">
            <div onClick={closeModal} className="cursor-pointer">
              <IoClose className="font-bold text-3xl" />
            </div>
          </div>
          <div className="mt-20 w-full h-full">
            <img src={MapPic} className="w-full h-full" />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ActiveVehicleLocationModal;
