import ArrowUp from "../../../assets/icons/arrow-up.svg";

const BalanceCard = () => {
  return (
    <div className="rounded-lg shadow-sm px-4 py-6 cursor-pointer flex flex-col items-center">
      <span className="font-bold text-base text-gray-500">Total balance</span>
      <h1 className="py-3 text-5xl font-extrabold">18,500.00</h1>
      <div className="flex items-center gap-2">
        <div className="rounded-full w-6 h-6 bg-custom-purple bg-opacity-20 flex items-center justify-center">
          <img src={ArrowUp} alt="arrow" />
        </div>
        <span className="text-base">12.81%</span>
      </div>
    </div>
  );
};

export default BalanceCard;
