import { useState } from "react";
import { BsSearch } from "react-icons/bs";
import Layout from "../../../layout/Layout";
import LogReportDataTable from "../../../components/home/log-report/LogReportDataTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  selectLogPage,
  setLogPage,
} from "../../../redux/features/navbarReducer";
import { useDispatch, useSelector } from "react-redux";

const LogReport = () => {
  const dispatch = useDispatch();
  const value = useSelector(selectLogPage);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  return (
    <Layout>
      <div className="max-w-6xl mx-auto h-full">
        <div className="flex flex-col sm:flex-row flex-wrap gap-4 mb-5 items-center">
          <div>
            <DatePicker
              showIcon
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              isClearable={true}
              placeholderText="9/11/2022 to 9/11/2022"
              className="px-3 py-2.5 rounded-xl border border-black shadow-all-rounded w-60"
            />
          </div>
          <div className="flex flex-grow items-center border rounded-xl border-black">
            <BsSearch size={25} className="ml-3 mr-1" />
            <input
              className="px-3 py-2 rounded-xl text-xl outline-0 w-full"
              type="search"
              placeholder="Search"
            />
          </div>
          <select
            onChange={(e) => dispatch(setLogPage(e.target.value))}
            className="border shadow-all-rounded px-3 py-2 rounded-xl outline-0 cursor-pointer w-fit text-lg font-semibold bg-[#F7F6DC]"
            value={value}
          >
            <option value="driver" className="w-fit font-semibold">
              Drivers
            </option>
            <option value="vehicle" className="w-fit font-semibold">
              Vehicles
            </option>
            <option value="routes" className="w-fit font-semibold">
              Routes
            </option>
          </select>
        </div>
        <div
          className="shadow-all-rounded rounded-xl"
          style={{ height: "85%" }}
        >
          <LogReportDataTable selectedTab={value} />
        </div>
      </div>
    </Layout>
  );
};

export default LogReport;
