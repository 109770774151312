import React from "react";

const handleClick = () => {
  
}

const FilterItem = ({ text }) => {
  return (
    <div className="flex items-center justify-between">
      <p className="font-bold text-lg">{text}</p>
      <input type="radio" className="w-5 h-7 cursor-pointer text-custom-green" name="selected" onClick={handleClick}/>
    </div>
  );
};

export default FilterItem;
