import { useRef, useState, useEffect } from "react";
import CameraBtn from "../../assets/images/svgs/camera.svg";
import deleteAccount from "../../assets/images/svgs/deleteAccount.svg";
import Input from "../input/Input";
import { useNavigate } from "react-router-dom";
import RoutesDirections from "../../routes/types";
import { selecProfilePicture } from "../../redux/features/managerReducer";
import { useSelector, useDispatch } from "react-redux";
import { setProfilePicture } from "../../redux/features/managerReducer";
import managerService from "../../services/manager";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Profile = () => {
  const hiddenFileInput = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileImgs = useSelector(selecProfilePicture);
  const [profilePictureURL, setProfilePictureURL] = useState(CameraBtn);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    setProfilePictureURL(fileUploaded);
    const response = await managerService.setProfilePicture(fileUploaded);
    if (!response.exception) {
      dispatch(setProfilePicture(response.picture));
      toast.success("Profile Picture Updated", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.error(response.errors, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  useEffect(() => {
    async function profilePicture() {
      const response = await managerService.getProfilePicture(
        localStorage.getItem("token")
      );
      if (!response.exception) {
        if (response.data.picture != null) {
          setProfilePictureURL(response.data.picture);
          dispatch(setProfilePicture(profilePictureURL));
        }
      }
    }
    profilePicture();
  }, [profilePictureURL, dispatch]);

  return (
    <div>
      <div className="flex sm:flex-row sm:gap-0 justify-between items-center flex-col gap-5 m-9 w-auto">
        <div className="flex flex-col space-y-4  sm:w-1/4">
          <h1 className="font-bold text-3xl">Profile</h1>
          <p className="text-[#606060] font-medium text-base">
            Update your photo and personal details.
          </p>
        </div>
        <div className="flex items-center flex-col">
          <div
            className=" overflow-hidden p-3 bg-white shadow-all-rounded h-24 w-24 rounded-full cursor-pointer flex items-center justify-center"
            onClick={() => handleChange}
          >
            <img
              src={profilePictureURL}
              alt="camera"
              className="object-contain h-full w-full rounded-full"
            />
            <input
              type="file"
              className="hidden"
              onChange={() => handleChange}
              ref={hiddenFileInput}
            />
          </div>
        </div>
        <div className="flex flex-row gap-5 px-2">
          <button className="rounded-2xl shadow-all-rounded w-20 h-10 py-1 text-xl font-bold">
            Cancel
          </button>
          <button className="rounded-2xl shadow-md w-20 h-10 py-1 text-xl font-bold text-white bg-light-orange hover:bg-orange-400">
            Save
          </button>
        </div>
      </div>
      <div className="my-10 flex items-center mx-8 mt-16">
        <div className="flex flex-col flex-grow max-w-xl">
          <Input name="Username" placeholder="Your Username" type="text" />
          <Input
            name="Phone Number"
            placeholder="Your phone number, e.g : +01 112 xxx xxx"
            type="number"
          />
          <Input name="Address" placeholder="Enter your address" type="text" />
        </div>
      </div>
      <div className="flex justify-between mx-8">
        <div className="flex space-x-2 items-center">
          <p className="text-red font-semibold text-lg">
            Deactivate your account
          </p>
          <img src={deleteAccount} alt="deleteAccount" className="h-6 w-6" />
        </div>
        <button
          type="button"
          className="bg-red font-bold text-white text-xl rounded-2xl px-2 py-2.5"
          onClick={() => navigate(RoutesDirections.DeactivateAccount)}
        >
          Click Here
        </button>
      </div>
    </div>
  );
};

export default Profile;
