import React from "react";

const Button = ({ text, type, isDisabled }) => {
  return (
    <div>
      <button
        type={type}
        className="font-bold text-lg bg-light-orange text-white py-3 px-[7.6875rem] rounded-[1.875rem] shadow-xl w-full"
        disabled={isDisabled}
      >
        {text}
      </button>
    </div>
  );
};

export default Button;
