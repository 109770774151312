import EditImg from "../../assets/images/svgs/edit.svg";

export default function TransportUserTables({ columns, TableData }) {
  const tableColumn = Object.keys(TableData[1]);
  return (
    <table className="min-w-full font-semibold justify-between overflow-y-auto">
      <thead className="bg-placeholder/50 rounded-t-xl">
        <tr className="text-sm sm:text-xl">
          <th scope="col" className="py-6 pl-4 sm:pl-10">
            <div className="flex items-center h-5">
              <input
                id="checkbox-all"
                type="checkbox"
                className="text-blue-600 border-gray-200 rounded focus:ring-blue-500"
              />
              <label htmlFor="checkbox" className="sr-only">
                Checkbox
              </label>
            </div>
          </th>
          {columns.map((data, key) => (
            <th key={key} className="py-3 text-500 capitalize">
              {data}
            </th>
          ))}
          <th scope="col" className="py-3 capitalize mx-auto pr-7">
            View
          </th>
        </tr>
      </thead>
      <tbody className="flex-grow overflow-y-scroll text-center">
        {TableData.map((row, i) => (
          <tr
            key={i}
            className="hover:bg-light-yellow cursor-pointer text-base sm:text-lg justify-between"
          >
            <td className="py-3 pl-4 sm:pl-10">
              <div className="flex items-center h-5">
                <input
                  type="checkbox"
                  className="text-blue-600 border-gray-200 rounded focus:ring-blue-500"
                />
                <label htmlFor="checkbox" className="sr-only">
                  Checkbox
                </label>
              </div>
            </td>
            {tableColumn.map((column, key) => (
              <td key={key} className="py-4 text-gray-800 whitespace-nowrap">
                {row[column]}
              </td>
            ))}
            <th>
              <img
                src={EditImg}
                className={`cursor-pointer mx-auto pr-7`}
                // onClick={() => setIsOpen(true)}
                alt="edit-icon"
              />
            </th>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
