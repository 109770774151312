import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profilePicture: "",
};
export const managerReducer = createSlice({
  name: "manager",
  initialState,
  reducers: {
    setProfilePicture: (state, action) => {
      state.profilePicture = action.payload;
    },
  },
});

export const { setProfilePicture } = managerReducer.actions;
export const selecProfilePicture = (state) => state.navbar.profilePicture;
export default managerReducer.reducer;
