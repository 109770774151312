import ReactCodeInput from "react-verification-code-input";

export default function AccountDeactivateCodeModal({
  handleCodeModal,
  codeModalIsOpen,
  handleConfirmModal,
}) {
  const handleCode = (e) => {
    console.log(e);
  };

  return (
    <div
      className={`${
        codeModalIsOpen === true ? "block" : "hidden"
      } fixed top-80 left-80 z-50  w-96 p-4 overflow-x-hidden overflow-y-auto backdrop-blur-sm`}
    >
      <div className="relative w-full h-full max-w-2xl md:h-auto">
        <div className="relative bg-[#00000040] rounded-lg shadow pb-1">
          <div className="flex justify-between p-4 flex-col items-center px-8">
            <h3 className="text-xl font-semibold text-black text-center pb-7">
              Enter six digit code here.
            </h3>
            <ReactCodeInput onComplete={(e) => handleCode(e)} />
          </div>
          <div className="flex justify-between mx-5 pt-7">
            <button
              className="bg-green text-white text-xl font-bold rounded-3xl  px-7 py-2.5"
              type="button"
              onClick={() => handleCodeModal(false)}
            >
              Cancel
            </button>
            <button
              className="bg-red text-white text-xl font-bold rounded-3xl px-7 py-2.5"
              type="button"
              onClick={() => {
                handleCodeModal(false);
                handleConfirmModal(true);
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
