import Modal from "react-modal";
import Input from "../input/Input";
import RouteImg from "../../assets/images/svgs/route3.svg";
import HomeImg from "../../assets/images/svgs/home3.svg";
import NumberImg from "../../assets/images/svgs/number.svg";
import useWindowSize from "../../utils/useWindowSize";
import Map from "../../assets/images/map-pic.png";

const RoutesModal = ({ modalIsOpen, setIsOpen, edit }) => {
  const { width } = useWindowSize();

  const customStyles = {
    content: {
      top: "95px",
      left: "auto",
      right: "10px",
      bottom: "auto",
      width:
        width < 768
          ? "23rem"
          : width > 768 && width < 1024
          ? "35rem"
          : width >= 1024 && "45rem",
      boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.25)",
      borderRadius: "20px",
      overflow: "hidden",
      padding: "0px",
    },
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="p-5 h-[76vh] overflow-y-auto">
          <div className="flex items-center mb-8 top-0">
            <div className="font-bold text-xl md:text-3xl flex-grow text-center">
              {edit ? "View/Edit Route" : "Add New Route"}
            </div>
          </div>
          <div className="mt-20">
            <div className="max-w-lg mx-auto ">
              <Input
                name="Route Name"
                type="text"
                placeholder="Enter Name, e.g : loader"
                icon={RouteImg}
              />
              <Input
                name="From"
                type="text"
                placeholder="By default city name"
                icon={HomeImg}
              />
              <div>
                <p className="font-bold text-sm sm:text-lg mb-4">
                  Tag Starting Point
                </p>
                <img src={Map} className="w-64 h-44 items-center" />
              </div>
              <Input
                name="To"
                type="text"
                placeholder="Type here, eg Islamabad"
                icon={HomeImg}
              />
              <div>
                <p className="font-bold text-sm sm:text-lg mb-4">
                  Tag Ending Point
                </p>
                <img src={Map} className="w-64 h-44 items-center" />
              </div>
              <Input
                name="Route No"
                type="number"
                placeholder="Enter in numbers"
                icon={NumberImg}
              />
            </div>
          </div>
        </div>
        {edit ? (
          <div className="flex justify-end rounded-b-xl gap-8 px-3 py-5 bg-placeholder/50">
            <div
              onClick={closeModal}
              className="bg-red flex items-center justify-center text-sm sm:text-lg text-white font-semibold rounded-3xl px-6 py-2 shadow-all-rounded cursor-pointer"
            >
              <p className="whitespace-nowrap">Delete</p>
            </div>
            <div
              className="bg-green flex items-center justify-center text-sm sm:text-lg text-white font-semibold rounded-3xl px-6 py-2 shadow-all-rounded cursor-pointer"
              onClick={closeModal}
            >
              <p className="whitespace-nowrap">Update</p>
            </div>
          </div>
        ) : (
          <div className="flex justify-end rounded-b-xl gap-8 px-3 py-5 bg-placeholder/50">
            <div
              onClick={closeModal}
              className="bg-white border flex items-center justify-center text-base sm:text-lg text-black font-semibold rounded-3xl px-6 py-2 shadow-all-rounded cursor-pointer"
            >
              <p className=" whitespace-nowrap">Cancel</p>
            </div>
            <div
              className="bg-green flex items-center justify-center text-base sm:text-lg text-white font-semibold rounded-3xl px-8 py-2 shadow-all-rounded cursor-pointer"
              onClick={closeModal}
            >
              <p className=" whitespace-nowrap">Add</p>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default RoutesModal;
