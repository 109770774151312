import EditImg from "../../assets/images/svgs/edit.svg";

export default function Table({
  TableContent,
  setIsOpen,
  columnNames,
  tableColumn,
  setId,
  setEdit,
}) {
  return (
    <div className="overflow-y-auto">
      <table className="table-fixed w-full sm:table-auto">
        <thead className="sticky top-0">
          <tr className="text-sm sm:text-xl bg-placeholder/50 rounded-t-xl font-bold justify-between">
            {columnNames.map((data, key) => (
              <td
                key={key}
                className={`py-5 capitalize ${
                  data === "Title" ? "text-start pl-6" : "text-center mx-auto"
                }`}
              >
                {data}
              </td>
            ))}
            <td className="text-center pr-3">View / Edit</td>
          </tr>
        </thead>
        {TableContent.map((row) => (
          <tbody
            className="w-full text-sm sm:text-xl font-semibold"
            key={[row].id}
          >
            <tr key={row.id}>
              {tableColumn.map((column, i) => (
                <td
                  key={i}
                  className={`py-3 ${
                    column == "name" ? "text-start pl-6" : "text-center mx-auto"
                  }`}
                >
                  {row[column]}
                </td>
              ))}
              <th>
                <img
                  src={EditImg}
                  className={`cursor-pointer mx-auto`}
                  onClick={() => {
                    setIsOpen(true);
                    setId(row.id);
                    setEdit(true);
                  }}
                  alt="edit-icon"
                />
              </th>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
}
