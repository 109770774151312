import { React, useState } from "react";
import ForgotImage from "../../assets/images/svgs/forgot.svg";
import Button from "../../components/button/Button";
import Header from "../../components/header/Header";
import { useNavigate } from "react-router-dom";
import RoutesDirections from "../../routes/types";
import managerService from "../../services/manager";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import "yup-phone-lite";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [btn, setBtn] = useState(false);
  const initialValues = {
    phone: "",
    otp: "",
    password: "",
    password_confirmation: "",
  };
  const validationSchema = yup.object({
    phone: yup
      .string()
      .phone("PK", "Please enter a valid phone number")
      .required("A phone number is required"),
    otp: yup
      .string()
      .min(4, "OTP must be of lenght 4")
      .max(4, "OTP must be of lenght 4")
      .required("Required"),
    password: yup
      .string()
      .min(8, "Password must be between 8 and 25 characters")
      .max(25, "Password must be between 8 and 25 characters")
      .required("Required"),
    password_confirmation: yup
      .string()
      .min(8)
      .max(25)
      .oneOf([yup.ref("password")], "Password doesn't match")
      .required("Required"),
  });
  const handleClick = async (values, { resetForm }) => {
    setBtn(true);
    const response = await managerService.forgetPassword({
      phone: values.phone,
      otp: values.otp,
      password: values.password,
      password_confirmation: values.password_confirmation,
    });
    if (!response.exception) {
      toast.success("Success", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      navigate(RoutesDirections.Login);
    } else {
      toast.error(response.errors, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    setBtn(false);
    resetForm();
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col-reverse lg:flex-row items-start justify-center mt-20 md:mt-10 mb-16 px-8">
        <div className="flex lg:ml-auto">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleClick}
          >
            <div className="flex flex-col flex-grow mx-auto">
              <div className=" mb-[2.625rem] text-center">
                <p className="font-bold text-lg sm:text-[2rem]">
                  Forgot Your Password!
                </p>
                <p className="text-placeholder text-[0.9375rem] mt-2">
                  Don’t worry enter some details to reset your password
                </p>
              </div>
              <Form className="flex flex-col">
                <label
                  htmlFor="phone"
                  className="font-bold text-sm sm:text-lg mb-2"
                >
                  Phone Number
                </label>
                <Field
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Your phone number, e.g : +01 112 xxx xxx"
                  className="flex items-center border border-placeholder mb-2 p-3 rounded-[1.25rem] text-sm sm:text-[0.9375rem] flex-grow"
                />
                <ErrorMessage name="phone" />

                <div className="lg:hidden text-xs py-3 px-[0.875rem] rounded-[1.875rem] border border-light-orange text-placeholder bg-light-yellow w-fit h-fit mb-4 cursor-pointer">
                  Get Code
                </div>

                <label
                  htmlFor="otp"
                  className="font-bold text-sm sm:text-lg mb-2"
                >
                  One Time Password
                </label>
                <Field
                  type="text"
                  id="otp"
                  name="otp"
                  placeholder="Enter Verification Code"
                  className="flex items-center border border-placeholder mb-2 p-3 rounded-[1.25rem] text-sm sm:text-[0.9375rem] flex-grow"
                />
                <ErrorMessage name="otp" />

                <label
                  htmlFor="password"
                  className="font-bold text-sm sm:text-lg mb-2"
                >
                  Create new Password
                </label>
                <Field
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Your password, at least 8 character."
                  className="flex items-center border border-placeholder mb-2 p-3 rounded-[1.25rem] text-sm sm:text-[0.9375rem] flex-grow"
                />
                <ErrorMessage name="password" />

                <label
                  htmlFor="password_confirmation"
                  className="font-bold text-sm sm:text-lg mb-2"
                >
                  Confirm Password
                </label>
                <Field
                  type="password"
                  id="password_confirmation"
                  name="password_confirmation"
                  placeholder="Re-type your password"
                  className="flex items-center border border-placeholder mb-2 p-3 rounded-[1.25rem] text-sm sm:text-[0.9375rem] flex-grow"
                />
                <ErrorMessage name="password_confirmation" />
                <div className="mt-4 flex-grow">
                  <Button text="Submit" type="submit" isDisabled={btn} />
                </div>
              </Form>
              <ToastContainer autoClose={5000} />
            </div>
          </Formik>
        </div>
        <div className="text-xs py-3 px-[0.875rem] hidden lg:block whitespace-nowrap rounded-[1.875rem] text-placeholder border border-light-orange bg-light-yellow w-fit h-fit mt-[8.75rem] ml-2 cursor-pointer">
          Get Code
        </div>
        <div className="flex max-w-xl mx-auto mb-10 lg:mb-0 lg:mr-0">
          <img src={ForgotImage} alt="login" />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
