import Request from "./request";

const fetchDrivers = async () => {
  try {
    const response = await Request.get("manager/web/driver");
    return { exception: false, data: response.data.data };
  } catch (error) {
    return { exception: true, errors: error.response.data.message };
  }
};

const createDriver = async (data) => {
  try {
    const response = await Request.post("manager/web/driver/", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return { exception: false, data: response.data };
  } catch (error) {
    return { exception: true, errors: error.response };
  }
};

export default { fetchDrivers, createDriver };
