import { TripData } from "../../../../data/logReportData";

const LogReportPdf = () => {
  return (
    <div className="h-[80vh] flex flex-col justify-between">
      <div className="overflow-x-auto">
        <div className="w-full inline-block align-middle">
          <div className="overflow-hidden w-fit">
            <table>
              <thead className="bg-placeholder/50 rounded-t-xl">
                <tr>
                  <th className="px-5 py-3 text-xl font-bold  text-500">
                    Date
                  </th>
                  <th className="px-1 py-3 text-xl font-bold  text-500">
                    Schedule Time
                  </th>
                  <th className="px-1 py-3 text-xl font-bold  text-500">
                    Driver
                  </th>
                  <th className="px-1 py-3 text-xl font-bold  text-500">
                    Vehicle Reg
                  </th>
                  <th className="px-1 py-3 text-xl font-bold  text-500">
                    Route No
                  </th>
                  <th className="px-5 py-3 text-xl font-bold  text-500 whitespace-normal">
                    Actual Trip Start / End Time
                  </th>
                </tr>
              </thead>
              <tbody className="flex-grow overflow-y-scroll">
                {TripData.map((data, i) => (
                  <tr key={i} className="hover:bg-light-yellow cursor-pointer text-center">
                    <td className="px-5 py-3 text-lg font-semibold text-gray-800 whitespace-nowrap">
                      {data.date}
                    </td>
                    <td className="px-1 py-3 text-lg font-bold text-gray-800 whitespace-nowrap">
                      Time
                    </td>
                    <td className="px-1 py-3 text-lg font-bold text-gray-800 whitespace-nowrap">
                      {data.driver}
                    </td>
                    <td className="px-1 py-3 text-lg font-semibold text-gray-800 whitespace-nowrap">
                      {data.vehicle_reg}
                    </td>
                    <td className="px-1 py-3 text-lg font-semibold text-gray-800 whitespace-normal">
                      {data.route_no}
                    </td>
                    <td className="px-5 py-3 text-lg font-semibold text-gray-800 whitespace-normal">
                      {data.time}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="bg-placeholder/50 p-5 rounded-b-xl flex space-y-3 md:space-y-0 items-end justify-end">
        <div
          className={`flex flex-col sm:flex-row items-center space-y-3 sm:space-x-11 cursor-pointer`}
        >
          <div className="bg-green text-white w-36 text-center text-xl py-2 px-5 rounded-3xl shadow-all-rounded">
            Save Pdf
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogReportPdf;
