import LogReportTableItem from "./LogReportTableItem";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import logReport from "../../../services/homeServices/logReport";

const LogReportDataTable = ({ selectedTab }) => {
  const navigate = useNavigate();
  const [DriverContent, setDriverContent] = useState([]);
  const [VehicleContent, setVehicleContent] = useState([]);
  const [RouteContent, setRouteContent] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await logReport.getAll();
      if (!response.exception) {
        setDriverContent(response.data.drivers);
        setVehicleContent(response.data.vehicles);
        setRouteContent(response.data.routes);
      }
    }
    getData();
  }, []);

  return (
    <div className="flex flex-col h-full">
      <div
        className={`flex ${"grid-cols-2"} bg-placeholder/50 p-3 rounded-t-xl font-bold gap-8 px-10 md:px-16 lg:px-28`}
      >
        <input
          type="checkbox"
          className="w-[1.025rem] cursor-pointer"
          name="parentCheckbox"
        />
        <p className="font-bold text-2xl">Title</p>
      </div>
      <div className="flex-grow overflow-y-auto">
        {selectedTab === "driver"
          ? DriverContent.map((row) => (
              <LogReportTableItem key={row.id} tab={row} />
            ))
          : null}
        {selectedTab === "vehicle"
          ? VehicleContent.map((row) => (
              <LogReportTableItem key={row.id} tab={row} />
            ))
          : null}
        {selectedTab === "routes"
          ? RouteContent.map((row) => (
              <LogReportTableItem key={row.id} tab={row} />
            ))
          : null}
      </div>
      <div className="bg-placeholder/50 p-5 rounded-b-xl flex space-y-3 md:space-y-0 items-end justify-end">
        <div
          className={`flex flex-col sm:flex-row items-center space-y-3 sm:space-x-11 cursor-pointer`}
          onClick={() => navigate("/logReport/" + selectedTab)}
        >
          <div className="bg-green text-white w-36 text-center py-3 px-4 rounded-3xl shadow-all-rounded">
            Create Report
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogReportDataTable;
