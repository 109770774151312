import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setNavValue } from "../../redux/features/navbarReducer";

const SideBarItem = ({ icon1, icon2, text, bool, link }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setNavValue({ [text.toLowerCase()]: true }));
    if (link) navigate(link);
  };

  return (
    <div
      className={`${
        bool ? "bg-green" : "hover:bg-green/40"
      } rounded-2xl h-12 flex items-center p-5 cursor-pointer mb-0.5`}
      onClick={handleClick}
    >
      <img src={bool ? icon1 : icon2} className="mr-7" alt={`${text}-icon`} />
      <p className={`text-base md:text-md font-semibold ${bool && "text-white"}`}>{text}</p>
    </div>
  );
};

export default SideBarItem;
