import { useState, useEffect } from "react";
import RoutesModal from "./RoutesModal";
import Table from "../tables/Table";
import route from "../../services/routes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RoutesTab = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const columnNames = ["Title", "Date"];
  const [TableContent, setData] = useState([{}]);
  const tableColumn = ["name", "created_at"];

  useEffect(() => {
    async function fetchAllRoutes() {
      const response = await route.getRoutes();
      if (!response.exception) {
        setData(response.data);
      } else {
        toast.error(response.errors, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
    fetchAllRoutes();
  }, []);

  return (
    <div className="h-full overflow-y-auto">
      <Table
        TableContent={TableContent}
        setIsOpen={setIsOpen}
        columnNames={columnNames}
        tableColumn={tableColumn}
      />
      <RoutesModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} edit />
    </div>
  );
};

export default RoutesTab;
