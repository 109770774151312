import { useRef } from "react";
import { BsUpload } from "react-icons/bs";

const ImageUploader = ({ text, fileError, field }) => {
  const hiddenFileInput = useRef();
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <div>
      <div className="mb-4 flex items-center justify-between mx-2">
        <div className="flex flex-col">
          <p className="font-bold text-base">{text}</p>
          {fileError && (
            <div style={{ color: "red" }}>
              {fileError[field.name] === false
                ? "Invalid type file error"
                : fileError[field.name]?.name}
            </div>
          )}
        </div>
        <div
          className="font-bold h-7 w-16 flex items-center justify-center shadow-all-rounded rounded-3xl cursor-pointer"
          onClick={handleClick}
        >
          <BsUpload />
        </div>

        <input
          {...field}
          type="file"
          className="hidden"
          accept="image/*"
          ref={hiddenFileInput}
        />
      </div>
    </div>
  );
};

export default ImageUploader;
