export default function NotificationMenu({ MenuItem }) {
  return (
    <div
      id="dropdown"
      className="z-40 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-64 dark:bg-gray-700 max-h-80 overflow-y-auto"
    >
      <ul className="py-2" aria-labelledby="dropdownButton">
        <li>
          <div className="block px-4 py-3 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer">
            Notification
          </div>
        </li>
      </ul>
    </div>
  );
}
