import { useState } from "react";
import Modal from "react-modal";
import useWindowSize from "../../utils/useWindowSize";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import "yup-phone-lite";
import driverService from "../../services/driver";
import ImageUploader from "../ImageUploader/ImageUploader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneImg from "../../assets/images/svgs/phone.svg";
import CardImg from "../../assets/images/svgs/card.svg";
import UserImg from "../../assets/images/svgs/user.svg";

const DriverModal = ({ modalIsOpen, setAddOpen, edit }) => {
  const { width } = useWindowSize();
  const [btn, setBtn] = useState(false);
  const [file, setFile] = useState({
    cnic_front: null,
    cnic_back: null,
    license_front: null,
    license_back: null,
  });
  const fileNames = [
    "cnic_front",
    "cnic_back",
    "license_back",
    "license_front",
  ];

  const customStyles = {
    content: {
      top: "95px",
      left: "auto",
      right: "10px",
      bottom: "auto",
      width:
        width < 768
          ? "23rem"
          : width > 768 && width < 1024
          ? "35rem"
          : width >= 1024 && "45rem",
      boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.25)",
      borderRadius: "20px",
      overflow: "hidden",
      padding: "0px",
    },
  };

  const closeModal = () => {
    setAddOpen(false);
  };

  const initialValues = {
    name: "",
    phone: "",
    cnic: "",
    license_no: "",
    cnic_front: null,
    cnic_back: null,
    license_front: null,
    license_back: null,
  };
  
  const validationSchema = yup.object({
    phone: yup
      .string()
      .phone("PK", "Please enter a valid phone number")
      .required("A phone number is required"),
    name: yup.string().required("Full Name Required"),
    cnic: yup.string().required("CNIC Number is Required"),
    license_no: yup.string().required("License Number is Required"),
    cnic_front: yup.mixed().required("CNIC Front is Required"),
    cnic_back: yup.mixed().required("CNIC Back Image is Required"),
    license_front: yup.mixed().required("License Front Image is Required"),
    license_back: yup.mixed().required("License Back Image is Required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    setBtn(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("phone", values.phone);
    formData.append("cnic", values.cnic);
    formData.append("license_no", values.license_no);
    formData.append("cnic_front", file.cnic_front);
    formData.append("cnic_back", file.cnic_back);
    formData.append("license_front", file.license_front);
    formData.append("license_back", file.license_back);
    const response = await driverService.createDriver(formData);
    if (!response.exception) {
      closeModal();
      toast.success("Driver Created", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      closeModal();
      toast.error(response.errors, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    resetForm();
    setFile({
      cnic_front: null,
      cnic_back: null,
      license_front: null,
      license_back: null,
    });
    setBtn(false);
  };

  function validateFile(file) {
    if (file.size > 5 * 1024 * 1024) {
      return false;
    }
    return true;
  }

  const handleFileChange = (event) => {
    const fileName = event.target.files[0];
    const isValid = validateFile(fileName);
    if (isValid) {
      setFile({ ...file, [event.target.name]: fileName });
    } else {
      setFile({ ...file, [event.target.name]: false });
    }
  };

  const handleFormChange = (e) => {
    if (fileNames.includes(e.target.name)) {
      handleFileChange(e);
    }
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form onChange={handleFormChange}>
            <div className="p-5 h-[76vh] overflow-y-auto">
              <div className="flex items-center mb-8 top-0">
                <div className="font-bold text-xl md:text-3xl flex-grow text-center">
                  {edit ? "View/Edit Driver" : "Add New Driver"}
                </div>
              </div>
              <div className="mt-20">
                <div className="max-w-lg mx-auto flex flex-col">
                  <label
                    htmlFor="name"
                    className="font-bold text-sm sm:text-lg mb-4"
                  >
                    Full Name
                  </label>
                  <div className="justify-between flex rounded-[1.25rem] items-center border border-placeholder mb-4 text-sm sm:text-[0.9375rem]">
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      className="p-3 rounded-[1.25rem] flex-grow outline-none"
                      placeholder="Name, e.g : John Doe"
                    />
                    <img src={UserImg} className="mr-4" />
                  </div>
                  <ErrorMessage name="name" />
                  <label
                    htmlFor="name"
                    className="font-bold text-sm sm:text-lg mb-4"
                  >
                    Phone Number
                  </label>
                  <div className="justify-between flex rounded-[1.25rem] items-center border border-placeholder mb-4 text-sm sm:text-[0.9375rem]">
                    <Field
                      type="text"
                      id="phone"
                      name="phone"
                      className="p-3 rounded-[1.25rem] flex-grow outline-none"
                      placeholder="Phone number, e.g : +01 112 xxx xxx"
                    />
                    <img src={PhoneImg} className="mr-4" />
                  </div>
                  <ErrorMessage name="phone" />
                  <label
                    htmlFor="cnic"
                    className="font-bold text-sm sm:text-lg mb-4"
                  >
                    CNIC Number
                  </label>
                  <div className="justify-between flex rounded-[1.25rem] items-center border border-placeholder mb-4 text-sm sm:text-[0.9375rem]">
                    <Field
                      type="text"
                      id="cnic"
                      name="cnic"
                      className="p-3 rounded-[1.25rem] flex-grow outline-none"
                      placeholder="CNIC number, e.g : +01 112 xxx xxx"
                    />
                    <img src={CardImg} className="mr-4" />
                  </div>
                  <ErrorMessage name="cnic" />
                  <Field
                    name="cnic_front"
                    fileError={file}
                    text="Attach Front Side of CNIC"
                    component={ImageUploader}
                  />
                  <ErrorMessage name="cnic_front" />
                  <Field
                    name="cnic_back"
                    fileError={file}
                    text="Attach Back Side of CNIC"
                    component={ImageUploader}
                  />
                  <ErrorMessage name="cnic_back" />
                  <label
                    htmlFor="license_no"
                    className="font-bold text-sm sm:text-lg mb-4"
                  >
                    License Number
                  </label>
                  <div className="justify-between flex rounded-[1.25rem] items-center border border-placeholder mb-4 text-sm sm:text-[0.9375rem]">
                    <Field
                      type="text"
                      id="license_no"
                      name="license_no"
                      className="p-3 rounded-[1.25rem] flex-grow outline-none"
                      placeholder="License number, e.g : +01 112 xxx xxx"
                    />
                    <img src={CardImg} className="mr-4" />
                  </div>
                  <ErrorMessage name="license_no" />
                  <Field
                    name="license_front"
                    fileError={file}
                    text="Attach Front Side of License"
                    component={ImageUploader}
                  />
                  <ErrorMessage name="license_front" />
                  <Field
                    name="license_back"
                    fileError={file}
                    text="Attach Back Side of License"
                    component={ImageUploader}
                  />
                  <ErrorMessage name="license_back" />
                </div>
              </div>
            </div>
            {edit ? (
              <div className="flex justify-end rounded-b-xl gap-8 px-3 py-5 bg-placeholder/50">
                <div
                  onClick={closeModal}
                  className="bg-red flex items-center justify-center text-sm sm:text-lg text-white font-semibold rounded-3xl px-6 py-2 shadow-all-rounded cursor-pointer"
                >
                  <p className=" whitespace-nowrap">Delete</p>
                </div>
                <div
                  className="bg-green flex items-center justify-center text-sm sm:text-lg text-white font-semibold rounded-3xl px-6 py-2 shadow-all-rounded cursor-pointer"
                  onClick={closeModal}
                >
                  <p className=" whitespace-nowrap">Update</p>
                </div>
              </div>
            ) : (
              <div className="flex justify-end rounded-b-xl gap-8 px-3 py-5 bg-placeholder/50">
                <div
                  onClick={closeModal}
                  className="bg-white border flex items-center justify-center text-base sm:text-lg text-black font-semibold rounded-3xl px-6 py-2 shadow-all-rounded cursor-pointer"
                >
                  <p className=" whitespace-nowrap">Cancel</p>
                </div>
                <div className="bg-green flex items-center justify-center text-base sm:text-lg text-white font-semibold rounded-3xl px-8 py-2 shadow-all-rounded cursor-pointer">
                  <button
                    type="submit"
                    className="whitespace-nowrap"
                    disabled={btn}
                  >
                    Add
                  </button>
                </div>
              </div>
            )}
          </Form>
        </Formik>
      </Modal>
    </div>
  );
};

export default DriverModal;
