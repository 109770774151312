import { Charts } from "./Charts";

const Statics = () => {
  return (
    <div>
      <div className="flex min-[1041px]:flex-row items-center justify-between max-[1040px]:flex-col">
        <h1 className="text-2xl font-semibold">Statistics</h1>
        <div className="flex flex-row items-center">
          <button className="bg-custom-green w-24 py-2 rounded-lg text-white font-semibold text-xs">
            Earning
          </button>
          <button className="w-24 py-2 rounded-lg text-xs font-semibold">Spending</button>
        </div>
      </div>
      <Charts />
    </div>
  );
};

export default Statics;
