import { useState } from "react";
import Layout from "../../layout/Layout";
import { BsSearch } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import Tab from "../../components/drivers/DriverTab";
import DriverModal from "../../components/drivers/DriverModal";

const Drivers = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  return (
    <Layout>
      <div className="max-w-6xl mx-auto h-full">
        <div className="flex flex-col sm:flex-row sm:items-center gap-4 mb-5">
          <div className="flex flex-grow items-center border border-black rounded-xl">
            <BsSearch size={25} className="ml-3 mr-1" />
            <input
              className="p-3 rounded-xl text-xl outline-0 w-full"
              type="search"
              placeholder="Search"
            />
          </div>
          <div
            onClick={() => setIsOpen(true)}
            className="bg-green flex items-center justify-center text-xl text-white font-semibold rounded-2xl p-3 shadow-all-rounded cursor-pointer"
          >
            <AiOutlinePlus className="font-semibold mr-1" />
            <p className=" whitespace-nowrap">Add Driver</p>
          </div>
        </div>
        <div
          className="shadow-all-rounded rounded-xl"
          style={{ height: "85%" }}
        >
          <Tab />
        </div>
        <DriverModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      </div>
    </Layout>
  );
};

export default Drivers;
