import React from "react";
import { useLocation } from "react-router-dom";

const Input = ({ type, name, placeholder, icon, handleChange, id }) => {
  const loc = useLocation();
  return (
    <div
      className={`${
        loc.pathname === "/profile"
          ? "flex justify-between items-center text-[#606060]"
          : "flex flex-col"
      }`}
    >
      <p className="font-bold text-sm sm:text-lg mb-4">{name}</p>
      <div className="flex items-center border border-placeholder rounded-[1.25rem] mb-4">
        <input
          type={type}
          className={`${
            loc.pathname === "/profile"
              ? "w-80 px-4 py-2 border-black border-[1px]"
              : "p-4"
          } rounded-[1.25rem] text-sm sm:text-[0.9375rem] outline-none flex-grow`}
          placeholder={placeholder}
          name={name}
          onChange={handleChange}
        />
        {icon && <img src={icon} alt="icon" className="w-7 mr-4" />}
      </div>
    </div>
  );
};

// w-[20.625rem]

export default Input;
