//icons
import { AiFillSetting } from "react-icons/ai";
//components
import NotificationItem from "./NotificationItem";
import Avatar from "../../../assets/icons/avatar.svg";
import bill from "../../../assets/icons/bill.svg";

const Notification = () => {
  return (
    <div className="my-5">
      <div className="flex flex-row items-center justify-between mb-2">
        <div className="flex flex-row items-center gap-2">
          <h1 className="text-base font-semibold text-gray-500 ">Notifications</h1>
          <span className="block w-2 h-2 bg-[#F66B62] rounded-full"></span>
        </div>
        <AiFillSetting size={20} className="cursor-pointer text-slate-500" />
      </div>
      <div>
        <NotificationItem
          icon={Avatar}
          title="Joseph Akbar"
          detail="just sent you 1000"
          time="15"
        />
        <NotificationItem
          icon={bill}
          title="Joseph Akbar"
          detail="just sent you 1000"
          time="15"
          earning={true}
        />
        <NotificationItem
          icon={Avatar}
          title="Joseph Akbar"
          detail="just sent you 1000"
          time="15"
        />
      </div>
    </div>
  );
};

export default Notification;
