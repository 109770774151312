import React, { useEffect, useState } from "react";
import { AiOutlinePlus, AiTwotoneFilter } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import ScheduleFilterModal from "../../../components/home/schedule/ScheduleFilterModal";
import ScheduleModal from "../../../components/home/schedule/ScheduleModal";
import ScheduleTab from "../../../components/home/schedule/ScheduleTab";
import Layout from "../../../layout/Layout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const Schedule = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [onEdit, setEdit] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [selec, setSelect] = useState("true");
  const [searchText, setSearchText] = useState("");
  const [filteredSchedules, setFilterSchedules] = useState(TableContent);

  useEffect(() => {
    const filteredSchedule = TableContent.filter((schedule) => {
      const time = schedule.time.toLowerCase();
      const route_no = schedule.route_no.toLowerCase();
      const vehicle_reg = schedule.vehicle_reg.toLowerCase();
      const driver = schedule.driver.toLowerCase();
      return (
        route_no.includes(searchText.toLowerCase()) ||
        time.includes(searchText.toLowerCase()) ||
        vehicle_reg.includes(searchText.toLowerCase()) ||
        driver.includes(searchText.toLowerCase())
      );
    });
    setFilterSchedules(filteredSchedule);
  }, [searchText]);

  return (
    <Layout>
      <div className="mx-auto h-full">
        {showPDF === true ? (
          <ScheduleTab
            showPDF={showPDF}
            create={selec}
            setShowPDF={setShowPDF}
            setEdit={setEdit}
            onEdit={onEdit}
            Content={filteredSchedules}
          />
        ) : (
          <>
            <div className="items-center flex flex-col lg:flex-row gap-4 mb-5">
              <div className="border shadow-all-rounded rounded-xl w-min">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="outline-none rounded-xl placeholder:font-black w-min"
                  isClearable
                  showIcon
                  placeholderText="Date"
                />
              </div>
              <div className="flex items-center border border-black rounded-xl w-full">
                <BsSearch size={25} className="ml-3 mr-1" />
                <input
                  className="px-3 py-2 rounded-xl text-xl outline-0 w-full"
                  type="search"
                  placeholder="Search"
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <AiTwotoneFilter
                  size={25}
                  onClick={() => setIsOpen2(true)}
                  className="mr-3 ml-1 cursor-pointer"
                />
              </div>
              {selec === "true" && (
                <div
                  onClick={() => {
                    setIsOpen(true);
                    setEdit(true);
                  }}
                  className="bg-green flex items-center justify-center text-xl text-white font-semibold rounded-2xl px-3 py-2 shadow-all-rounded cursor-pointer"
                >
                  <AiOutlinePlus className="font-semibold mr-1" />
                  <p className="whitespace-nowrap">Add</p>
                </div>
              )}
              <select
                onChange={(e) => setSelect(e.target.value)}
                className="border text-base sm:text-xl font-semibold text-[#606060] p-2 shadow-all-rounded rounded-2xl outline-0 bg-[#F7F6DC] w-fit"
              >
                <option value="true">Create Schedule</option>
                <option value="false">Published Schedule</option>
              </select>
            </div>
            <div
              className="shadow-all-rounded rounded-xl"
              style={{ height: "85%" }}
            >
              <ScheduleTab
                create={selec}
                showPDF={showPDF}
                setShowPDF={setShowPDF}
                setEdit={setEdit}
                onEdit={onEdit}
                Content={filteredSchedules}
              />
            </div>
            <ScheduleModal
              modalIsOpen={modalIsOpen}
              setIsOpen={setIsOpen}
              onEdit={onEdit}
            />
            <ScheduleFilterModal
              modalIsOpen={modalIsOpen2}
              setIsOpen={setIsOpen2}
            />
          </>
        )}
      </div>
    </Layout>
  );
};

const TableContent = [
  {
    time: "20:24",
    route_no: "10-Gujranwala To Faisalabad",
    vehicle_reg: "MLT-987",
    driver: "Ali",
  },
  {
    time: "1:24",
    route_no: "15-Gujranwala To Islamabad",
    vehicle_reg: "LHR-123",
    driver: "Azam",
  },
  {
    time: "07:24",
    route_no: "20-Gujranwala To Multan",
    vehicle_reg: "GWR-345",
    driver: "Atif",
  },
  {
    time: "07:24",
    route_no: "20-Gujranwala To Multan",
    vehicle_reg: "GWR-345",
    driver: "Atif",
  },
  {
    time: "07:24",
    route_no: "20-Gujranwala To Multan",
    vehicle_reg: "GWR-345",
    driver: "Atif",
  },
  {
    time: "07:24",
    route_no: "20-Gujranwala To Multan",
    vehicle_reg: "GWR-345",
    driver: "Atif",
  },
  {
    time: "07:24",
    route_no: "20-Gujranwala To Multan",
    vehicle_reg: "GWR-345",
    driver: "Atif",
  },
  {
    time: "07:24",
    route_no: "20-Gujranwala To Multan",
    vehicle_reg: "GWR-345",
    driver: "Atif",
  },
  {
    time: "07:24",
    route_no: "20-Gujranwala To Multan",
    vehicle_reg: "GWR-345",
    driver: "Atif",
  },
  {
    time: "07:24",
    route_no: "20-Gujranwala To Multan",
    vehicle_reg: "GWR-345",
    driver: "Atif",
  },
  {
    time: "07:24",
    route_no: "20-Gujranwala To Multan",
    vehicle_reg: "GWR-345",
    driver: "Atif",
  },
  {
    time: "07:24",
    route_no: "20-Gujranwala To Multan",
    vehicle_reg: "GWR-345",
    driver: "Atif",
  },
  {
    time: "07:24",
    route_no: "20-Gujranwala To Multan",
    vehicle_reg: "GWR-345",
    driver: "Atif",
  },
];
export default Schedule;
