import HomeImg1 from "../../assets/images/svgs/home.svg";
import HomeImg2 from "../../assets/images/svgs/home2.svg";
import DriverImg1 from "../../assets/images/svgs/driver2.svg";
import DriverImg2 from "../../assets/images/svgs/driver.svg";
import VehicleImg1 from "../../assets/images/svgs/vehicle2.svg";
import VehicleImg2 from "../../assets/images/svgs/vehicle.svg";
import RouteImg1 from "../../assets/images/svgs/route2.svg";
import RouteImg2 from "../../assets/images/svgs/route.svg";
import ProfileImg1 from "../../assets/images/svgs/profile2.svg";
import ProfileImg2 from "../../assets/images/svgs/profile.svg";
import CodeImg1 from "../../assets/images/svgs/code2.svg";
import CodeImg2 from "../../assets/images/svgs/code.svg";
import WalletImg1 from "../../assets/images/svgs/wallet2.svg";
import WalletImg2 from "../../assets/images/svgs/wallet.svg";
import HelpImg1 from "../../assets/images/svgs/help3.svg";
import HelpImg2 from "../../assets/images/svgs/help4.svg";
import LogoutImg from "../../assets/images/svgs/logout.svg";
import SideBarItem from "./SideBarItem";
import RoutesDirections from "../../routes/types";
import managerService from "../../services/manager";
import { useDispatch, useSelector } from "react-redux";
import {
  selectNavValue,
  setNavValue,
} from "../../redux/features/navbarReducer";
import { useNavigate } from "react-router-dom";

const SideBar = () => {
  const value = useSelector(selectNavValue);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="w-80 my-6 rounded-r-xl shadow-all-rounded bg-white h-auto">
      <div className="p-5">
        <SideBarItem
          icon1={HomeImg1}
          icon2={HomeImg2}
          text="Home"
          bool={value.home}
          link={RoutesDirections.Home}
        />
        <SideBarItem
          icon1={DriverImg1}
          icon2={DriverImg2}
          text="Drivers"
          bool={value.drivers}
          link={RoutesDirections.Drivers}
        />
        <SideBarItem
          icon1={VehicleImg1}
          icon2={VehicleImg2}
          text="Vehicles"
          bool={value.vehicles}
          link={RoutesDirections.Vehicles}
        />
        <SideBarItem
          icon1={RouteImg1}
          icon2={RouteImg2}
          text="Routes"
          bool={value.routes}
          link={RoutesDirections.Routes}
        />
      </div>
      <hr />
      <div className="px-3">
        <SideBarItem
          icon1={ProfileImg1}
          icon2={ProfileImg2}
          text="Profile"
          bool={value.profile}
          link={RoutesDirections.Profile}
        />
        <SideBarItem
          icon1={CodeImg1}
          icon2={CodeImg2}
          text="Code for Parents/Users"
          bool={value["code for parents/users"]}
          link={RoutesDirections.QR}
        />
        <SideBarItem
          icon1={WalletImg1}
          icon2={WalletImg2}
          text="Wallet"
          bool={value.wallet}
          link={RoutesDirections.Wallet}
        />
        <SideBarItem
          icon1={HelpImg1}
          icon2={HelpImg2}
          text="Help"
          bool={value.help}
          link={RoutesDirections.Help}
        />
        <div
          className="hover:bg-green/40 rounded-2xl h-12 flex items-center p-5 cursor-pointer transition-all ease-in-out"
          onClick={() => {
            managerService.logout(localStorage.getItem("token"));
            dispatch(setNavValue({ logout: true }));
            navigate(RoutesDirections.Login);
          }}
        >
          <img src={LogoutImg} className="mr-7" alt="home-icon" />
          <p className="text-base md:text-md font-semibold text-red">logout</p>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
