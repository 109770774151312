const CustomCard = ({ title, amount, icon, percentage }) => {
  return (
    <div className="rounded-lg shadow-sm hover:shadow-xl p-4 cursor-pointer">
      <span className="font-bold text-sm text-gray-500">{title}</span>
      <h1 className="py-2 text-2xl font-bold">{amount}</h1>
      <div className="flex items-center gap-2">
        <div className={`border rounded-full w-6 h-6 ${title === "Earning" ? 'bg-custom-purple': 'bg-light-orange'} bg-opacity-20 flex items-center justify-center`}>
          <img src={icon} alt="arrow" />
        </div>
        <span className="text-sm">{percentage}%</span>
      </div>
    </div>
  );
};

export default CustomCard;
