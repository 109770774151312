import { configureStore } from "@reduxjs/toolkit";
import navbarReducer from "../features/navbarReducer";
import managerReducer from "../features/managerReducer";

export const store = configureStore({
  reducer: {
    navbar: navbarReducer,
    manager: managerReducer,
  },
});
